import { Flex, FlexProps } from '@chakra-ui/react'

type StepperRootProps = FlexProps

export function StepperRoot({ ...props }: StepperRootProps) {
  return (
    <Flex w="100%" justify="center" alignItems="center" gap="0.5rem" {...props}>
      {props.children}
    </Flex>
  )
}
