import { useQuery } from 'react-query'
import { services } from '../../../logic/core'

const { requests } = services

export const useGetRequest = (
  registerPerPage: number,
  currentPage: number,
  orphanId: number,
  tutorId?: number
) => {
  const getRequest = useQuery(
    [`request`, registerPerPage, currentPage, orphanId, tutorId],
    () => requests.get({ registerPerPage, currentPage, orphanId,tutorId }),
    {
      staleTime: 1000 * 60 * 10 // 10 minutes
    }
  )

  return { getRequest }
}
