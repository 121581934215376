import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSidebarDrawer } from '../../data/contexts'
import { SidebarNav } from './SidebarNav'

export function Sidebar() {
  const location = useLocation()
  const { isOpen, onClose } = useSidebarDrawer()
  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false,
  })

  useEffect(() => {
    if (isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (isDrawerSidebar) {
    return (
      <Drawer isOpen={isOpen} placement={'left'} onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg={'blue.300'} p={'4'}>
            <DrawerCloseButton color={'platinum.50'} mt={'6'} />
            <DrawerHeader color={'platinum.50'}>Navegação</DrawerHeader>

            <DrawerBody>
              <SidebarNav />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }

  return (
    <Box as={'aside'} w={64} mr={'8'}>
      <SidebarNav />
    </Box>
  )
}
