import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface ButtonOutlineBlueProps extends ButtonProps {
  icon: IconType
}

export function ButtonOutlineBlue({ icon, ...props }: ButtonOutlineBlueProps) {
  return (
    <Button
      borderRadius="0.25rem"
      variant="outline"
      leftIcon={<Icon as={icon} />}
      size="md"
      color="blue.50"
      borderColor="blue.50"
      _hover={{ bg: 'blue.200', color: 'white' }}
      cursor="pointer"
      {...props}
    >
      {props.children}
    </Button>
  )
}
