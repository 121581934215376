import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spinner,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { BsPencilSquare } from 'react-icons/bs'
import {
  AlertComponent,
  Input,
  SectionDivider,
  SectionForm,
  SectionSubtitle,
  SectionTitle,
} from '../../components'
import { useGetTutor } from '../../data/hooks'
import { CEP, CPF, RG, Telefone } from '../../logic/utils'
import { ModalDadosPessoais } from './components/ModalDadosPessoais'
import { ModalEndereco } from './components/ModalEndereco'

export function DetailTutor() {
  const { getTutor } = useGetTutor()
  const { data } = getTutor

  const {
    onOpen: onOpenPessoais,
    onClose: onClosePessoais,
    isOpen: isOpenPessoais,
  } = useDisclosure()
  const {
    onOpen: onOpenEndereco,
    onClose: onCloseEndereco,
    isOpen: isOpenEndereco,
  } = useDisclosure()

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  document.title = 'RN-ACOLHE - Detalhar tutor'

  return (
    <>
      <ModalDadosPessoais
        data={data}
        title={'Dados pessoais'}
        isOpen={isOpenPessoais}
        onClose={onClosePessoais}
      />

      <ModalEndereco
        data={data}
        title={'Endereço'}
        isOpen={isOpenEndereco}
        onClose={onCloseEndereco}
      />

      <Box
        flex={'1'}
        borderRadius={isWideVersion ? '8' : '0'}
        bg={'platinum.100'}
        p={['6', '8']}
      >
        <Heading
          fontSize={{ base: '1.5rem', md: '1.875rem' }}
          fontWeight={'normal'}
        >
          Detalhar Tutor
        </Heading>

        {getTutor.isLoading ? (
          <Flex color={'blue.300'} justify={'center'} align={'center'}>
            <Spinner />
          </Flex>
        ) : getTutor.error ? (
          <AlertComponent
            status="error"
            title="Ocorreu um erro"
            description={'Não foi possível detalhar o tutores.'}
          />
        ) : data ? (
          <>
            <Flex flexDirection={'column'}>
              <SectionForm icon={BsPencilSquare} onOpen={onOpenPessoais}>
                <SectionTitle>Dados de pessoais</SectionTitle>
                <SectionSubtitle>Dados pessoais do tutor</SectionSubtitle>
                <SectionDivider />
              </SectionForm>

              <Grid
                templateAreas={{
                  base: `"nome" "cpf"`,
                  sm: `"nome cpf"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: '65.887% 1fr',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'nome'}>
                  <Input
                    label="Nome completo"
                    type={'text'}
                    placeholder={data?.data.nome}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'cpf'}>
                  <Input
                    label="CPF"
                    type={'text'}
                    // mask={'999.999.999-99'}
                    placeholder={CPF.format(data?.data.cpf)}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"numeroRg" "orgaoRg"`,
                  sm: `"numeroRg orgaoRg"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: '1fr 1fr',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'numeroRg'}>
                  <Input
                    label="Número do RG"
                    type={'text'}
                    placeholder={RG.format(data?.data.rg)}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'orgaoRg'}>
                  <Input
                    label="Órgão expedidor do RG"
                    type={'text'}
                    placeholder={data?.data.uf_orgao_expedidor}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"email" "telefone" "telAlt"`,
                  sm: `"email email" "telefone telAlt"`,
                  md: `"email telefone telAlt"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'email'}>
                  <Input
                    label="E-mail"
                    type={'email'}
                    placeholder={data?.data.email}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'telefone'}>
                  <Input
                    label="Telefone"
                    type={'text'}
                    //   mask={'(99) 99999-9999'}
                    placeholder={Telefone.format(data?.data.telefone)}
                    isReadOnly
                    //   {...register('data_nascimento')}
                    //   error={errors.data_nascimento}
                  />
                </GridItem>

                <GridItem area={'telAlt'}>
                  <Input
                    label="Telefone/Cel alternativo"
                    type={'text'}
                    placeholder={Telefone.format(
                      data?.data.telefone_alternativo,
                    )}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <SectionForm icon={BsPencilSquare} onOpen={onOpenEndereco}>
                <SectionTitle>Dados de endereço</SectionTitle>
                <SectionSubtitle>Dados de endereço do tutor</SectionSubtitle>
                <SectionDivider />
              </SectionForm>

              <Grid
                templateAreas={{
                  base: `"cep" "cidade" "bairro"`,
                  sm: `"cep cep" "cidade bairro"`,
                  md: `"cep cidade bairro"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                marginBottom={'2rem'}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'cep'}>
                  <Input
                    label="CEP"
                    type={'text'}
                    //   mask={'99999-999'}
                    placeholder={CEP.format(data?.data.endereco.cep)}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'cidade'}>
                  <Input
                    label="Cidade"
                    type={'text'}
                    placeholder={data?.data.endereco.municipio.toString()}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'bairro'}>
                  <Input
                    label="Bairro"
                    type={'text'}
                    placeholder={data?.data.endereco.bairro}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"logradouro" "numero" "uf"`,
                  sm: `"logradouro logradouro" "numero uf"`,
                  md: `"logradouro numero uf"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                marginBottom={'2rem'}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'logradouro'}>
                  <Input
                    label="Logradouro"
                    type={'text'}
                    placeholder={data?.data.endereco.logradouro}
                    isReadOnly
                  />
                </GridItem>
                <GridItem area={'numero'}>
                  <Input
                    label="Número"
                    type={'text'}
                    placeholder={data?.data.endereco.numero}
                    isReadOnly
                  />
                </GridItem>
                <GridItem area={'uf'}>
                  <Input
                    label="UF"
                    type={'text'}
                    placeholder={data?.data.endereco.uf}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"complemento" "pontoRef"`,
                  sm: `"complemento pontoRef"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: '1fr 1fr',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'complemento'}>
                  <Input
                    label="Complemento"
                    type={'text'}
                    placeholder={data?.data.endereco.complemento}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'pontoRef'}>
                  <Input
                    label="Ponto de referência"
                    type={'text'}
                    placeholder={data?.data.endereco.ponto_de_referencia}
                    isReadOnly
                  />
                </GridItem>
              </Grid>
            </Flex>
          </>
        ) : null}
      </Box>
    </>
  )
}
