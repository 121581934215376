import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import { Header, Sidebar } from '../components'

export function DefaultLayout() {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <Box>
      <Header />

      <Flex
        w={'100%'}
        mb={isWideVersion ? 6 : 0}
        maxWidth={1480}
        minHeight={isWideVersion ? 0 : 'calc(100vh - 100px)'}
        mx={'auto'}
        px={isWideVersion ? 6 : 0}
      >
        <Sidebar />

        <Outlet />
      </Flex>
    </Box>
  )
}
