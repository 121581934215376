import { Link, LinkProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface CardLinkProps extends LinkProps {
  children: ReactNode
}

export function CardLink({ children, ...rest }: CardLinkProps) {
  return (
    <Link fontSize="0.875rem" isExternal color="blue.400" {...rest}>
      {children}
    </Link>
  )
}
