import { SignInCredentials } from './SignInCredentials'
import { AuthResponse } from './AuthResponse'
import { api } from '../../../services/axios'

export const ServiceLogin = {
  async login(
    credentials: SignInCredentials,
  ): Promise<AuthResponse> {
    const { data } = await api.post('login/', credentials)
    return data
  },
}
