import { ChakraProps, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface TextSlideProps extends ChakraProps {
  children: ReactNode
}

export function TextSlide({ children, ...rest }: TextSlideProps) {
  return (
    <Text
      fontSize={{ base: 'sm', sm: 'sm', md: 'md', lg: 'lg' }}
      fontWeight={'regular'}
      lineHeight={'1.3125rem'}
      {...rest}
    >
      {children}
    </Text>
  )
}
