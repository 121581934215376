import { Text, TextProps } from '@chakra-ui/react'

type CardNumberProps = TextProps

export function CardNumber({ children, ...props }: CardNumberProps) {
  return (
    <Text
      justifyContent="center"
      alignItems="center"
      minWidth="3.3125rem"
      textAlign="center"
      fontSize="2.25rem"
      color="gray.300"
      {...props}
      // color={document ? 'white' : 'gray.900'}
      // borderRadius="50%"
      // bg={document ? 'green.50' : 'gray.100'}
    >
      {children}
    </Text>
  )
}
