import { GetAttachmentsOutput } from '../../hooks/get_attachments/GetAttachmentsOutput'
import { ActionTypes } from '../ActionTypes'
import { GetAttachmentsState } from './'

export function getAttachmentsReducer(
  state: GetAttachmentsState,
  action: ActionTypes<GetAttachmentsOutput>,
) {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        attachments: action.payload.results,
        attachedFiles: action.payload.results.map((anexo) => anexo.tipo),
        requiredAttachments: action.payload.results.filter(
          (anexo) => anexo.tipo !== 5,
        ).length,
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
