import { ChakraProps, FormErrorMessage } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface FormErrorMessageItemProps extends ChakraProps {
  children?: ReactNode
}

export function FormErrorMessageItem({
  children,
  ...rest
}: FormErrorMessageItemProps) {
  return (
    <FormErrorMessage
      fontSize={'md'}
      lineHeight={'1.1875rem'}
      margin={'0.625rem 0 0 0'}
      {...rest}
    >
      {children}
    </FormErrorMessage>
  )
}
