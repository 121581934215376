import { AttachFileActionTypes } from './AttachFileActionTypes'
import { AttachFileState } from './AttachFileState'

export function attachFileReducer(
  state: AttachFileState,
  action: AttachFileActionTypes,
) {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
        error: undefined,
        abortController: action.abortController,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        progress: 100,
        success: action.success,
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
        errorData: action.errorData,
      }
    case 'FETCH_FINALLY':
      return {
        ...state,
        loading: false,
        abortController: undefined,
        error: undefined,
        errorData: undefined,
        progress: 0,
        success: '',
      }
    default:
      return state
  }
}
