import * as yup from 'yup'

export const DeceasedFormValidatorSchema = yup.object({
  nome: yup
    .string()
    .required('Nome completo é obrigatório')
    .min(2, 'Nome deve ter no mínimo 2 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CPF deve conter apenas números')
    .min(11, 'CPF deve ter no mínimo 11 caracteres')
    .max(11, 'CPF deve ter no máximo 11 caracteres'),
  numero_certidao_obito: yup
    .string()
    .required('Número da certidão de óbito é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'Número da certidão de óbito deve conter apenas números'),
  data_emissao_certidao_obito: yup
    .string()
    .required('Data de emissão da certidão de óbito é obrigatório'),
  uf_emissao_certidao_obito: yup
    .string()
    .required('UF de emissão da certidão de óbito é obrigatório')
    .matches(/^[A-Z]{2}$/, 'UF de emissão da certidão de óbito deve conter apenas 2 caracteres'),
    morte_por_covid: yup
    .string()
    .nullable()
    .required('Morte por COVID-19 é obrigatório'),
    recebia_beneficio: yup
    .string()
    .nullable()
    .required('Recebia benefício é obrigatório'),
    fonte_beneficio: yup
    .string()
    .when('recebia_beneficio', {
      is: 'S',
      then: yup
        .string()
        .required('Fonte do benefício é obrigatório')
    }),
    valor_beneficio: yup
    .string()
    .when('recebia_beneficio', {
      is: 'S',
      then: yup
        .string()
        .required('Valor do benefício é obrigatório')
    }),
})

export const RegisterRequestFormValidatorSchema = yup.object().shape({
  tipo_orfandade: yup.string()
  .required('Tipo de orfandade é obrigatório'),
  falecido_um: DeceasedFormValidatorSchema.shape({}),
  falecido_dois: yup.object().when("tipo_orfandade",{
      is: '2',
      then: DeceasedFormValidatorSchema.shape({})
  }),
})