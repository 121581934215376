import { Heading, HeadingProps } from '@chakra-ui/react'

type TitleProps = HeadingProps

export function Title({ children, ...props }: TitleProps) {
  return (
    <Heading
      as="h1"
      fontSize={{ base: '1rem', md: '1.25rem' }}
      fontWeight={'semibold'}
      lineHeight={'1.438rem'}
      color={'black.900'}
      {...props}
    >
      {children}
    </Heading>
  )
}
