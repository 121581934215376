import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { services } from '../../../logic/core'
import { useAuth } from '../../contexts'
import {
  ACTION_TYPES,
  getAttachmentsReducer,
  INITIAL_STATE_ATTACHMENTS,
} from '../../reducers'
import { GetAttachmentsOutput } from './GetAttachmentsOutput'

export function useGetAttachments() {
  const { user } = useAuth()
  const tutorId = user?.tutor_id
  const { orphanId, requestId } = useParams()
  const [documentsAttached, setDocumentsAttached] = useState<number>(0)
  const [arrayAttachments, setArrayAttachments] = useState<number[]>([])
  const [state, dispatch] = useReducer(
    getAttachmentsReducer,
    INITIAL_STATE_ATTACHMENTS,
  )

  const signal = useMemo(() => {
    const controller = new AbortController()
    return controller.signal
  }, [])

  const execute = useCallback(
    async function () {
      dispatch({ type: ACTION_TYPES.FETCH_START })
      try {
        const config = {
          signal: signal,
        }
        const data =
          await services.documents.getAttachments<GetAttachmentsOutput>(
            { requestId, tutorId, orphanId, config },
          )
        setDocumentsAttached(data.results.filter((anexo) => anexo.tipo !== 5).length)
        setArrayAttachments(data.results.map((anexo) => anexo.tipo))
        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: data })
      } catch (attachmentsError: any) {
        const detail = attachmentsError?.response.data.detail as string[]
        if (detail) {
          return dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            payload: detail[0],
          })
        }
        dispatch({
          type: ACTION_TYPES.FETCH_ERROR,
          payload: 'Ocorreu um erro ao buscar os anexos.',
        })
      }
    },
    [tutorId, orphanId, requestId, signal],
  )

  useEffect(() => {
    if (!!tutorId && !!orphanId && !!requestId) {
      execute()
    }
  }, [orphanId, requestId, tutorId, execute])

  return {
    ...state,
    documentsAttached,
    setDocumentsAttached,
    arrayAttachments,
    setArrayAttachments,
    execute,
  }
}
