import { GetRequestByIdOutput } from '../../hooks'
import { ActionTypes } from '../ActionTypes'
import { GetRequestByIdState } from './GetRequestByIdState'

export function getRequestByIdReducer(
  state: GetRequestByIdState,
  action: ActionTypes<GetRequestByIdOutput>,
) {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        request: action.payload,
        requiredByIdDocuments: action.payload.anexos.filter(
          (anexo) => anexo.tipo !== 5,
        ).length,
        requestByIdSteps: action.payload.anexos.map((anexo) => anexo.tipo),
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
