import {
  Link as ChakraLink,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export function Subscribe() {
  return (
    <Stack spacing="0.5rem">
      <HStack>
        <Divider borderColor={'gray.700'} />
        <Text lineHeight={'0.875rem'} fontSize={'0.75rem'}>
          ou
        </Text>
        <Divider borderColor={'gray.700'} />
      </HStack>

      <Flex
        gap="1"
      >
        <Text lineHeight={'1.188rem'}>Não tem uma conta?</Text>
        <ChakraLink
          as={Link}
          to="sign-up"
          lineHeight={'1.188rem'}
          fontWeight="normal"
          fontSize="md"
          color={'blue.50'}
        >
          Inscrever-se
        </ChakraLink>
      </Flex>
    </Stack>
  )
}
