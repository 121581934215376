import { AxiosResponse } from 'axios'
import { api } from '../../../services/axios'
import { Params, Response } from '../share'
import { OrphanData, TypeOrphan } from './'

export class ServicesOrphan {
  async get(params: Omit<Params, 'orphanId'>): Promise<Response<TypeOrphan>> {
    const { registerPerPage, currentPage } = params
    const url = `tutor-orfaos/?limit=${registerPerPage}&offset=${
      (currentPage - 1) * registerPerPage
    }`
    const {
      data: { count, results },
    } = await api.get<Response<TypeOrphan>>(url)
    return { count, results }
  }

  async create(orphanData: OrphanData): Promise<AxiosResponse<any, any>> {
    const url = 'orfaos/'
    const response = await api.post(url, orphanData)
    return response
  }
}
