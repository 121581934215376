import { CardContent } from './CardContent'
import { CardJustification } from './CardJustification'
import { CardLabel } from './CardLabel'
import { CardName } from './CardName'
import { CardNumber } from './CardNumber'
import { CardRequiredField } from './CardRequiredField'
import { CardRoot } from './CardRoot'
import { CardWrap } from './CardWrap'

export const CardDocument = {
  Label: CardLabel,
  Name: CardName,
  Number: CardNumber,
  RequiredField: CardRequiredField,
  Root: CardRoot,
  Content: CardContent,
  Justification: CardJustification,
  Wrap: CardWrap,
}
