import { Text, TextProps } from '@chakra-ui/react'

type CardInfoProps = TextProps

export function CardInfo({ children, ...rest }: CardInfoProps) {
  return (
    <Text as="span" fontWeight="normal" ml="0.25rem" {...rest}>
      {children}
    </Text>
  )
}
