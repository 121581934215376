import { ChakraProps, FormLabel, FormLabelProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface FormLabelItemProps extends ChakraProps, FormLabelProps {
  disabled?: boolean
  children: ReactNode
}

export function FormLabelItem({
  disabled = false,
  children,
  ...rest
}: FormLabelItemProps) {
  return (
    <FormLabel
      margin={'0 0 0.25rem 0'}
      lineHeight={'1.1875rem'}
      opacity={disabled ? 0.4 : 1}
      cursor={disabled ? 'not-allowed' : 'auto'}
      {...rest}
    >
      {children}
    </FormLabel>
  )
}
