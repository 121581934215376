import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Error,
  GuardianFormData,
  GuardianFormValidatorSchema,
  services,
} from '../../logic/core'
import { Errors } from '../../logic/utils/Errors'

export function useGuardian() {
  const navigate = useNavigate()
  const { guardians } = services

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<GuardianFormData>({
    resolver: yupResolver(GuardianFormValidatorSchema),
  })

  const handleSignUp: SubmitHandler<GuardianFormData> = async (
    values,
    event,
  ) => {
    event?.preventDefault()
    await new Promise((resolve) => setTimeout(resolve, 2000))

    delete values.confirmation_password

    try {
      const { data } = await guardians.create(values)
      toast.success(`${data.mensagem}`)
      navigate('/')
    } catch (error: any) {
      if (error.response.status === 400) {
        const data = error.response.data
        const mapErrors = Errors.mapErrors(data)

        if (mapErrors) {
          mapErrors.forEach(({ type, name, message }: Error) => {
            setError(name, { type, message })
          })
          toast.error(
            'Ocorreu um erro ao tentar cadastrar o tutor, verifique os campos em destaque!',
          )
        }
      } else {
        toast.error(
          'Ocorreu um erro ao tentar cadastrar o tutor, tente novamente mais tarde!',
        )
      }
    }
  }

  return {
    register,
    handleSubmit,
    handleSignUp,
    errors,
    isSubmitting,
  }
}
