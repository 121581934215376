import { createContext, useContext } from 'react'
import { useGetRequestFilteredById } from '../hooks'

interface GetRequestFilteredByIdContextProps {
  children: React.ReactNode
}

type GetRequestFilteredByIdContextData = ReturnType<
  typeof useGetRequestFilteredById
>

export const GetRequestFilteredByIdContext = createContext(
  {} as GetRequestFilteredByIdContextData,
)

export function GetRequestFilteredByIdProvider({
  children,
}: GetRequestFilteredByIdContextProps) {
  const value = useGetRequestFilteredById()

  return (
    <GetRequestFilteredByIdContext.Provider value={value}>
      {children}
    </GetRequestFilteredByIdContext.Provider>
  )
}

export function useRequestFilteredById(): GetRequestFilteredByIdContextData {
  const context = useContext(GetRequestFilteredByIdContext)

  if (!context) {
    throw new Error(
      'useGetRequestFilteredById deve ser usado em um GetRequestFilteredByIdProvider',
    )
  }

  return context
}
