import { Flex, FlexProps } from '@chakra-ui/react'

type CardFlexProps = FlexProps

export function CardFlex({ children, ...rest }: CardFlexProps) {
  return (
    <Flex
      boxShadow="2px 2px 4px rgba(0, 0, 0, 0.25)"
      bgColor="platinum.100"
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      rounded="8"
      {...rest}
    >
      {children}
    </Flex>
  )
}
