import { Textarea, TextareaProps } from '@chakra-ui/react'

type OpinionProps = TextareaProps & {
  text: string
}

export function Opinion({ text, ...rest }: OpinionProps) {
  const longTextDetected = text.length > 27

  return (
    <Textarea
      variant="unstyled"
      fontSize="md"
      color="gray.400"
      resize={longTextDetected ? 'vertical' : 'none'}
      height={longTextDetected ? '48px' : '24px'}
      minHeight={longTextDetected ? '48px' : '24px'}
      maxHeight="72px"
      padding="0 0.5rem 0 0"
      marginBottom="2.5rem"
      isReadOnly
      defaultValue={`"${text}"`}
      {...rest}
    />
  )
}
