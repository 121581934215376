import { Modal, ModalProps } from '@chakra-ui/react'

type ModalRootProps = ModalProps

export function ModalRoot({ children, ...props }: ModalRootProps) {
  return (
    <Modal isCentered motionPreset="scale" {...props}>
      {children}
    </Modal>
  )
}
