import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom'
import {
  AttachmentsProvider,
  GetRequestFilteredByIdProvider,
  refreshTokenIsValid,
} from '../data/contexts'
import { DefaultLayout } from '../layouts/DefaultLayout'
import {
  AttachDocumentation,
  FixRequest,
  OrphanList,
  RedefinePassword,
  RegisterOrphan,
  RegisterRequest,
  RequestsList,
  SignIn,
  SignUp,
} from '../pages'
import { DetailTutor } from '../pages/DetailTutor'

interface PrivateRouteProps {
  children: React.ReactElement
  redirectTo: string
}

function PrivateRoute({ children, redirectTo }: PrivateRouteProps) {
  return refreshTokenIsValid() ? children : <Navigate to={redirectTo} />
}

export function Routes() {
  return (
    <ReactRoutes>
      <Route index element={<SignIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="redefine-password" element={<RedefinePassword />} />
      <Route
        path="orphans"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<OrphanList />} />
        <Route path="create" element={<RegisterOrphan />} />
        <Route path=":orphanId/requests" element={<RequestsList />} />
        <Route
          path=":orphanId/requests/create/"
          element={<RegisterRequest />}
        />
        <Route
          path=":orphanId/requests/:requestId/documents"
          element={
            <AttachmentsProvider>
              <AttachDocumentation />
            </AttachmentsProvider>
          }
        />
        <Route
          path=":orphanId/requests/:requestId/fix"
          element={
            <GetRequestFilteredByIdProvider>
              <FixRequest />
            </GetRequestFilteredByIdProvider>
          }
        />
        <Route path="*" element={<OrphanList />} />
      </Route>

      <Route
        path="profile"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<DetailTutor/>} />
      </Route>
      <Route path="*" element={<SignIn />} />
    </ReactRoutes>
  )
}
