import { Flex, FlexProps } from '@chakra-ui/react'

interface FormRootProps<T> extends FlexProps {
  attachment?: T,
  nameFile?: string,
  formRef?: React.MutableRefObject<any>
}

export function FormRoot<T>({
  children,
  attachment,
  formRef,
  nameFile,
  ...props
}: FormRootProps<T>) {
  return (
    <Flex
      as="form"
      ref={formRef}
      flexDirection={{
        base: 'column-reverse',
        sm: 'row',
        md: 'column-reverse',
        xl: 'row',
      }}
      alignItems="center"
      justifyContent="space-between"
      gap={attachment || nameFile ? { base: '1rem', lg: '1.5rem', xl: '1.5rem' } : '0'}
      width="100%"
      {...props}
    >
      {children}
    </Flex>
  )
}
