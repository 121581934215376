type Attachment = {
  id: number
  label: string
  type: number
  required: boolean
}

export const listOfAttachments: Attachment[] = [
  {
    id: 1,
    label: 'Certidão de nascimento ou documento oficial de adoção',
    type: 1,
    required: true,
  },
  {
    id: 2,
    label: 'Certidões de óbitos dos falecidos',
    type: 2,
    required: true,
  },
  { id: 3, label: 'Folha resumo cadastro único', type: 3, required: true },
  {
    id: 4,
    label:
      'Comprovante de inscrição da Criança/Adolescente no Cadastro de Pessoa Física (CPF)',
    type: 4,
    required: true,
  },
  {
    id: 5,
    label:
      'Certidão emitida pela instituição que gere o regime de previdência do(s) falecido(s)',
    type: 5,
    required: false,
  },
  {
    id: 6,
    label: 'Comprovantes de renda familiar (anterior ao óbito)',
    type: 6,
    required: true,
  },
  {
    id: 7,
    label:
      'Termo de responsabilidade legal emitido pelo Conselho Tutelar/Guarda expedida por Autoridade Judiciária',
    type: 7,
    required: true,
  },
  {
    id: 8,
    label:
      'Documentos de identificação do Responsável Legal (RG, CPF, Comprovante de Residência)',
    type: 8,
    required: true,
  },
  {
    id: 9,
    label: 'Termo de responsabilidade de comunicação de ocorrências preenchido',
    type: 9,
    required: true,
  },
  {
    id: 10,
    label: 'Comprovação do domicílio da Criança/Adolescente',
    type: 10,
    required: true,
  },
]
