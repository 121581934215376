import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles.css'

export function Toast() {
  return (
    <ToastContainer
      position='top-center'
      autoClose={3000}
      limit={3}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme='colored'
      style={{
        top: '0',
        padding: '0',
      }}
    />
  )
}
