import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useBreakpointValue,
  Flex,
  SimpleGrid
} from '@chakra-ui/react'

interface AlertProps {
  status: 'success' | 'error' | 'warning' | 'info'
  title: string
  description: string
}

export function AlertComponent({
  status = 'info',
  title,
  description
}: AlertProps) {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  return (
    <SimpleGrid flex={'1'} minChildWidth={'20rem'}>
    <Flex justify={'center'} align={'center'}>
      <Alert
        status={status}
        variant={'solid'}
        flexDirection="column"
        p={'2rem'}
        height="12.375rem"
        textAlign={'center'}
        borderRadius={isWideVersion ? '0.5rem' : '0'}
        gap={'0.5rem'}
      >
        <AlertIcon boxSize={'2.5rem'}/>
        <AlertTitle fontSize="lg" lineHeight={'1.313rem'}>
          {title}
        </AlertTitle>
        <AlertDescription maxWidth="sm"  lineHeight={'1.188rem'} maxW={'20.625rem'}>{description}</AlertDescription>
      </Alert>
    </Flex>
  </SimpleGrid>
  )
}
