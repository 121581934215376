import { Coin } from '../../../logic/utils'
import { Deceased, DeceasedData, RegisterRequestFormData } from '../interfaces'

export function formatDeceasedData(deceased: Deceased) {
  const deceasedData: DeceasedData = {
    nome: deceased.nome,
    cpf: deceased.cpf,
    numero_certidao_obito: deceased.numero_certidao_obito,
    data_emissao_certidao_obito: deceased.data_emissao_certidao_obito,
    uf_emissao_certidao_obito: deceased.uf_emissao_certidao_obito,
    morte_por_covid: deceased.morte_por_covid !== 'N',
    recebia_beneficio: deceased.recebia_beneficio !== 'N',

    fonte_beneficio: deceased.fonte_beneficio
      ? Number(deceased.fonte_beneficio)
      : null,

    valor_beneficio: deceased.valor_beneficio
      ? parseFloat(Coin.unFormat(deceased.valor_beneficio))
      : null,
  }
  return deceasedData
}

export function formatRequestCreateData(
  values: RegisterRequestFormData,
  orphanId: number,
) {
  const tipo_orfandade = Number(values.tipo_orfandade)

  if (tipo_orfandade === 1) {
    return {
      orfao: orphanId,
      status: 1,
      avaliador: null,
      tipo_orfandade: tipo_orfandade,

      falecido_um: formatDeceasedData(values.falecido_um),
      falecido_dois: null,
    }
  }
  return {
    orfao: orphanId,
    status: 1,
    avaliador: null,
    tipo_orfandade: tipo_orfandade,
    falecido_um: formatDeceasedData(values.falecido_um),

    falecido_dois: values.falecido_dois
      ? formatDeceasedData(values.falecido_dois)
      : null,
  }
}
