import { Flex, FlexProps } from '@chakra-ui/react'

type CardWrapProps = FlexProps

export function CardWrap({ children, ...rest }: CardWrapProps) {
  return (
    <Flex
      overflow="hidden"
      width="100%"
      borderRadius="8px"
      flexDirection={{ base: 'column', sm: 'row' }}
      boxShadow={
        '2px 2px 4px rgba(0, 0, 0, 0.25), -1px 1px 2px rgba(0, 0, 0, 0.25);'
      }
      {...rest}
    >
      {children}
    </Flex>
  )
}
