import { FormLabel, FormLabelProps } from '@chakra-ui/react'
import { type FieldError } from 'react-hook-form'

interface FormInputProps extends FormLabelProps {
  errors?: FieldError
}

export function FormInput({children, errors, ...props}: FormInputProps) {
  return (
    <FormLabel
      height="100%"
      display="flex"
      margin={0}
      flexDirection={{
        base: 'column-reverse',
        sm: 'row',
        md: 'column-reverse',
        xl: 'row',
      }}
      alignItems="center"
      justifyContent="space-between"
      gap={errors ? { base: '1rem', lg: '1.5rem', xl: '1.5rem' } : '0'}
      width={errors ? '100%' : 'none'}
      {...props}
    >
      {children}
    </FormLabel>
  )
}
