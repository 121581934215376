import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface ButtonOutlineRedProps extends ButtonProps {
  icon: IconType
}

export function ButtonOutlineRed({ icon, ...props }: ButtonOutlineRedProps) {
  return (
    <Button
      borderRadius="0.25rem"
      variant="outline"
      leftIcon={<Icon as={icon} />}
      size="md"
      color="red.500"
      borderColor="red.500"
      _hover={{ bg: 'red.500', color: 'white' }}
      cursor="pointer"
      {...props}
    >
      {props.children}
    </Button>
  )
}
