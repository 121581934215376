import { Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import LogoRNAcolhe from '../../assets/logo.png'


export function Logo() {
  return (
    <Link to="/orphans">
      <Image h={{base: '2rem', 'md': '3rem'}} objectFit="cover" src={LogoRNAcolhe} alt="RN-ACOLHE" />
    </Link>
  )
}
