import { GetRequestFilteredByIdState } from './'

export const INITIAL_STATE_GET_REQUEST_FILTERED_BY_ID: GetRequestFilteredByIdState =
  {
    loading: false,
    error: '',
    steps: [],
    allAttachments: 0,
    correctedAttachments: 0,
    request: {
      id: 0,
      nome: '',
      status: '',
      created_at: '',
      municipio: '',
      parecer: '',
      anexos: [
        {
          id: 0,
          arquivo: '',
          valido: null,
          tipo: '',
          justificativa: '',
          nome_original: '',
        },
      ],
    },
  }
