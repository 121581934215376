import { Flex, FlexProps } from '@chakra-ui/react'

type CardLabelProps = FlexProps

export function CardLabel({ children, ...props }: CardLabelProps) {
  return (
    <Flex
      overflow="hidden"
      width="100%"
      minWidth={{ base: '100%', md: '21.4513rem' }}
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      justifyContent={{ base: 'center', sm: 'start' }}
      gap="1rem"
      {...props}
    >
      {children}
    </Flex>
  )
}
