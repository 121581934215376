import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

interface CircularProgressProps {
  value: number
  maxPercent: number
}

export function CircularProgressComponent({
  value,
  maxPercent,
}: CircularProgressProps) {
  
  return (
    <CircularProgress
      value={value * 10}
      color={value === 9 ? 'blue.400' : 'green.400'}
      size={'6.25rem'}
      thickness="0.75rem"
      max={maxPercent}
    >
      <CircularProgressLabel fontWeight={'bold'} fontSize={'2.25rem'}>
        {value}
      </CircularProgressLabel>
    </CircularProgress>
  )
}
