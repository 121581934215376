import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

type RefreshToken = {
  token_type: string
  exp: number
  iat: number
  jti: string
  user_id: number
  tutor_id: number
  nome: string
  cpf: string
}

export function isAuthenticated() {
  const refreshToken = Cookies.get('refreshToken')
  if (refreshToken) {
    const { exp, tutor_id } = jwt_decode<RefreshToken>(refreshToken)
    const currentTime = Date.now() / 1000
    if (exp < currentTime && !tutor_id) {
      return false
    }
    return true
  } else {
    return false
  }
}

export function getUserData() {
  const refreshToken = Cookies.get('refreshToken')
  if (refreshToken) {
    const { tutor_id, nome, cpf } = jwt_decode<RefreshToken>(refreshToken)
    return { tutor_id, nome, cpf }
  } else {
    return {
      tutor_id: 0,
      nome: '',
      cpf: ''
    }
  }
}

export function getToken() {
  return Cookies.get('accessToken')
}

export function getRefreshToken() {
  return Cookies.get('refreshToken')
}

export function login({
  access,
  refresh
}: {
  access: string
  refresh: string
}) {
  Cookies.set('accessToken', access, {
    expires: 30 // 30 days
  })
  Cookies.set('refreshToken', refresh, {
    expires: 30 // 30 days
  })
}

export function logout() {
  Cookies.remove('accessToken')
  Cookies.remove('refreshToken')
}

export function decodeJwt(jwt: any) {
  return jwt_decode(jwt)
}
