import {
  ModalFooter as ModalFooterBody,
  ModalFooterProps as ChakraModalBodyProps,
} from '@chakra-ui/react'

type ModalFooterProps = ChakraModalBodyProps

export function ModalFooter({ children, ...props }: ModalFooterProps) {
  return (
    <ModalFooterBody
      alignItems="center"
      justifyContent="center"
      gap="1rem"
      {...props}
    >
      {children}
    </ModalFooterBody>
  )
}
