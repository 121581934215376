import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
} from '@chakra-ui/react'

type ModalHeaderProps = ChakraModalHeaderProps

export function ModalHeader({ children, ...props }: ModalHeaderProps) {
  return (
    <ChakraModalHeader textAlign="center" {...props}>
      {children}
    </ChakraModalHeader>
  )
}
