import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { RiCloseLine } from 'react-icons/ri'
import {
  Input,
  Modal,
  ModalConfirm,
  SecondaryButton,
  SectionDivider,
  TertiaryButton,
} from '../../../components'
import { useUpdateTutorPessoal } from '../../../data/hooks'
import { queryClient } from '../../../services/queryClient'

export function ModalDadosPessoais({
  data,
  title,
  onConfirm,
  onClose,
  isOpen,
}: any) {
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()
  const { register, handleSubmit, updatePessoal, reset, errors, isSubmitting } =
    useUpdateTutorPessoal({ onCloseModal: onClose, onCloseConfirm })
  const modalSize = useBreakpointValue({ base: 'xs', md: 'xl', '3xl': 'xl' })

  const handleConfirm = async () => {
    await handleSubmit(updatePessoal)()
    queryClient.invalidateQueries('tutor')
    onCloseConfirm()
    if (!errors) {
      onClose()
    }
  }

  const handleOnClose = () => {
    onClose()
    reset()
  }

  return (
    <>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isSubmitting={isSubmitting}
        title={'Confirmar atualização'}
        description={
          'Após a confirmação, os campos modificados serão salvos. Deseja realmente confirmar essa alteração?'
        }
        onConfirm={handleConfirm}
      />

      <Modal.Root size={modalSize} onClose={handleOnClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <Box>
            <Modal.Header>
              <Flex
                justify="space-between"
                alignItems="center"
                width="100%"
                gap="10px"
              >
                <Flex
                  justify="center"
                  align="flex-start"
                  textTransform="uppercase"
                >
                  {title}
                </Flex>
                <IconButton
                  aria-label="Fechar modal"
                  color="blackAlpha.900"
                  icon={<Icon as={RiCloseLine} fontSize="1.5rem" />}
                  mt="0.20rem"
                  size="xs"
                  variant="unstated"
                  onClick={handleOnClose}
                />
              </Flex>
              <SectionDivider />
            </Modal.Header>

            <Modal.Body>
              <Grid
                templateAreas={{
                  base: `"email" "telefone" "telAlt"`,
                  sm: `"email email" "telefone telAlt"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'email'}>
                  <Input
                    label="E-mail"
                    type={'email'}
                    defaultValue={data?.data.email}
                    {...register('email')}
                    error={errors.email}
                  />
                </GridItem>

                <GridItem area={'telefone'}>
                  <Input
                    label="Telefone"
                    type={'text'}
                    mask={'(99) 99999-9999'}
                    defaultValue={data?.data.telefone}
                    {...register('telefone')}
                    error={errors.telefone}
                  />
                </GridItem>

                <GridItem area={'telAlt'}>
                  <Input
                    label="Telefone/Cel alternativo"
                    type={'text'}
                    mask={'(99) 99999-9999'}
                    defaultValue={data?.data.telefone_alternativo}
                    {...register('telefone_alternativo')}
                    error={errors.telefone_alternativo}
                  />
                </GridItem>
              </Grid>
            </Modal.Body>
            <Modal.Footer>
              <TertiaryButton onClick={handleOnClose}>Fechar</TertiaryButton>
              <SecondaryButton
                onClick={onOpenConfirm}
                isLoading={isSubmitting}
                loadingText="Confirmando"
                disabled={Object.keys(errors).length > 0}
              >
                Confirmar
              </SecondaryButton>
            </Modal.Footer>
          </Box>
        </ModalContent>
      </Modal.Root>
    </>
  )
}
