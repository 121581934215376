import { CardContent } from './CardContent'
import { CardFlex } from './CardFlex'
import { CardFooter } from './CardFooter'
import { CardInfo } from './CardInfo'
import { CardLabel } from './CardLabel'
import { CardStatus } from './CardStatus'
import { CardTitle } from './CardTitle'

export const Card = {
  Content: CardContent,
  Flex: CardFlex,
  Footer: CardFooter,
  Info: CardInfo,
  Label: CardLabel,
  Status: CardStatus,
  Title: CardTitle,
}

export * from './CardContent'
export * from './CardFlex'
export * from './CardFooter'
export * from './CardInfo'
export * from './CardLabel'
export * from './CardStatus'
export * from './CardTitle'
