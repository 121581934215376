import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FaleConoscotValidatorSchema } from '../../logic/core/'

interface UseFaleConoscoProps {
  onCloseModal: () => void
}

interface FaleConoscoFormData {
  nome: string
  cpf: string
  contato: string
  mensagem: string
}

export function useFaleConosco({ onCloseModal }: UseFaleConoscoProps) {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<FaleConoscoFormData>({
    resolver: yupResolver(FaleConoscotValidatorSchema),
    mode: 'all',
  })

  const onSubmit: SubmitHandler<FaleConoscoFormData> = async (values) => {
    try {
      // await services.faleConosco(values)
      console.log('values', values)
      onCloseModal()
      toast.success('Mensagem enviada com sucesso!')
    } catch (error: any) {
      toast.error('Erro ao enviar mensagem: ' + error?.response?.data?.mensagem)
    }
  }

  return {
    register,
    handleSubmit,
    setError,
    reset,
    onSubmit,
    trigger,
    errors,
    isSubmitting,
  }
}
