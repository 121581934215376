import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { Toast } from './components'
import { AuthProvider, SidebarDrawerProvider } from './data/contexts'
import { Routes } from './routes'
import { queryClient } from './services/queryClient'
import { theme } from './styles/theme'

const isDev = process.env.NODE_ENV === 'development'

export const App = () => (
  <Router>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Toast />
          <SidebarDrawerProvider>
            <Routes />
          </SidebarDrawerProvider>
        </ChakraProvider>
        {isDev && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </AuthProvider>
  </Router>
)
