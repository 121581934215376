import { InfoIcon } from '@chakra-ui/icons'
import { ChakraProps, FormHelperText } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface FormHelperTextItemProps extends ChakraProps {
  children: ReactNode
}

export function FormHelperTextItem({ children, ...rest }: FormHelperTextItemProps) {
  return (
    <FormHelperText
      borderRadius={'md'}
      bg={'platinum.300'}
      boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
      color={'black.900'}
      fontSize={'md'}
      margin={'0.625rem 0 0 0'}
      {...rest}
    >
      <InfoIcon margin={'0 0.25rem 0 0'} color={'blue.100'} />
      {children}
    </FormHelperText>
  )
}
