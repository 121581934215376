import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { CarouselLogin, Login } from './components'

export function SignIn() {
  document.title = 'RN-ACOLHE - Login'

  const isMobileVersion = useBreakpointValue({
    base: true,
    sm: false,
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex
      height={{ base: '100%', '2xl': '100vh' }}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Flex direction={{ base: 'column', lg: 'row' }}>
        {isMobileVersion ? (
          <>
            <Button
              onClick={() => onOpen()}
              display={'flex'}
              gap={'0.5rem'}
              borderRadius={'0rem 0rem 3rem 3rem'}
              boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
              padding={'0.75rem 1.5rem'}
              height={'1rem'}
              bgColor={'platinum.50'}
              position={'fixed'}
              top={'0'}
              transform={'translate(-50%, 0)'}
              left={'50%'}
              zIndex={'1'}
            >
              <Text>Login</Text>
              <AiOutlineCaretDown />
            </Button>
            <Drawer
              blockScrollOnMount={false}
              placement={'top'}
              onClose={onClose}
              isOpen={isOpen}
              size={'full'}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton marginRight={'0.5rem'} />
                <Login />
              </DrawerContent>
            </Drawer>
          </>
        ) : (
          <Login />
        )}
        <CarouselLogin />
      </Flex>
    </Flex>
  )
}
