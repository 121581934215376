import { Flex, FlexProps } from '@chakra-ui/react'

interface CardWrapProps extends FlexProps {
  children: React.ReactNode
}

export function CardWrap({ children, ...rest }: CardWrapProps) {
  return (
    <Flex
      borderRadius="8px"
      boxShadow="2px 2px 4px rgba(0, 0, 0, 0.25)"
      overflow="hidden"
      w="100%"
      {...rest}
    >
      {children}
    </Flex>
  )
}
