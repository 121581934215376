import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { queryClient } from '../../services/queryClient'
import { api } from '../../services/axios'

type User = {
  tutor_id: number
  nome: string
  cpf: string
}

type AuthContextData = {
  signIn: (access: string, refresh: string) => void
  signOut: () => void
  user?: User
  isAuthenticated: boolean
}

const AuthContext = createContext({} as AuthContextData)

type AuthProviderProps = {
  children: ReactNode
}

type Token = {
  token_type: string
  exp: number
  iat: number
  jti: string
  user_id: number
  tutor_id: number
  nome: string
  cpf: string
}

export function getToken(): string | undefined {
  return Cookies.get('accessToken')
}

export function getRefreshToken(): string | undefined {
  return Cookies.get('refreshToken')
}

export function jwtDecode(token: string): Readonly<Token> {
  return jwt_decode(token)
}

export function refreshTokenIsValid(): boolean {
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    return false
  }
  const { exp, iat } = jwtDecode(refreshToken)
  const now = new Date().getTime() / 1000
  return exp > now && iat < now
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const accessToken = getToken()
    const refreshToken = getRefreshToken()

    async function refreshTokenApi() {
      const url = `/refresh/`
      const response = await api.post(url, { refresh: refreshToken })
      const { access: accessToken } = response.data 
      if (accessToken && refreshToken) {
        signIn(accessToken, refreshToken)
      }
    }

    if (accessToken && refreshToken) {
      const { exp } = jwtDecode(accessToken)
      const now = new Date().getTime() / 1000

      // Chame refreshToken se o token de acesso estiver prestes a expirar
      if (exp < now + 60 * 5) {
        // Chame refreshToken se faltar menos de 5 minutos para a expiração
        refreshTokenApi()
      }

      const { tutor_id, nome, cpf } = jwtDecode(accessToken)
      setUser({ tutor_id, nome, cpf })
      setIsAuthenticated(true)
    } else {
      signOut()
    }
  }, []) // eslint-disable-line

  async function signIn(access: string, refresh: string): Promise<void> {
    if (access && refresh) {
      const { tutor_id, nome, cpf } = jwtDecode(access)
      if (tutor_id) {
        Cookies.set('accessToken', access, {
          expires: 60 * 15, // 15 minutos
        })

        Cookies.set('refreshToken', refresh, {
          expires: 1, // 1 dia
        })

        setUser({ tutor_id, nome, cpf })
        setIsAuthenticated(true)
      }
    }
  }

  function signOut(): void {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')

    queryClient.removeQueries()

    setIsAuthenticated(false)
    setUser(undefined)
  }

  const value = useMemo(
    () => ({ signIn, signOut, user, isAuthenticated }),
    [user, isAuthenticated],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth deve ser usado em um AuthProvider')
  }

  return context
}
