import { County } from '../../logic/core'

export const counties: County[] = [
  { id: 1, name: 'Acari' },
  { id: 2, name: 'Açu' },
  { id: 3, name: 'Afonso Bezerra' },
  { id: 4, name: 'Água Nova' },
  { id: 5, name: 'Alexandria' },
  { id: 6, name: 'Almino Afonso' },
  { id: 7, name: 'Alto do Rodrigues' },
  { id: 8, name: 'Angicos' },
  { id: 9, name: 'Antônio Martins' },
  { id: 10, name: 'Apodi' },
  { id: 11, name: 'Areia Branca' },
  { id: 12, name: 'Arês' },
  { id: 13, name: 'Augusto Severo' },
  { id: 14, name: 'Baía Formosa' },
  { id: 15, name: 'Baraúna' },
  { id: 16, name: 'Barcelona' },
  { id: 17, name: 'Bento Fernandes' },
  { id: 18, name: 'Bodó' },
  { id: 19, name: 'Bom Jesus' },
  { id: 20, name: 'Brejinho' },
  { id: 21, name: 'Caiçara do Norte' },
  { id: 22, name: 'Caiçara do Rio do Vento' },
  { id: 23, name: 'Caicó' },
  { id: 24, name: 'Campo Redondo' },
  { id: 25, name: 'Canguaretama' },
  { id: 26, name: 'Caraúbas' },
  { id: 27, name: 'Carnaúba dos Dantas' },
  { id: 28, name: 'Carnaubais' },
  { id: 29, name: 'Ceará-Mirim' },
  { id: 30, name: 'Cerro Corá' },
  { id: 31, name: 'Coronel Ezequiel' },
  { id: 32, name: 'Coronel João Pessoa' },
  { id: 33, name: 'Cruzeta' },
  { id: 34, name: 'Currais Novos' },
  { id: 35, name: 'Doutor Severiano' },
  { id: 36, name: 'Encanto' },
  { id: 37, name: 'Equador' },
  { id: 38, name: 'Espírito Santo' },
  { id: 39, name: 'Extremoz' },
  { id: 40, name: 'Felipe Guerra' },
  { id: 41, name: 'Fernando Pedroza' },
  { id: 42, name: 'Florânia' },
  { id: 43, name: 'Francisco Dantas' },
  { id: 44, name: 'Frutuoso Gomes' },
  { id: 45, name: 'Galinhos' },
  { id: 46, name: 'Goianinha' },
  { id: 47, name: 'Governador Dix-Sept Rosado' },
  { id: 48, name: 'Grossos' },
  { id: 49, name: 'Guamaré' },
  { id: 50, name: 'Ielmo Marinho' },
  { id: 51, name: 'Ipanguaçu' },
  { id: 52, name: 'Ipueira' },
  { id: 53, name: 'Itajá' },
  { id: 54, name: 'Itaú' },
  { id: 55, name: 'Jaçanã' },
  { id: 56, name: 'Jandaíra' },
  { id: 57, name: 'Janduís' },
  { id: 58, name: 'Januário Cicco' },
  { id: 59, name: 'Japi' },
  { id: 60, name: 'Jardim de Angicos' },
  { id: 61, name: 'Jardim de Piranhas' },
  { id: 62, name: 'Jardim do Seridó' },
  { id: 63, name: 'João Câmara' },
  { id: 64, name: 'João Dias' },
  { id: 65, name: 'José da Penha' },
  { id: 66, name: 'Jucurutu' },
  { id: 67, name: 'Jundiá' },
  { id: 68, name: "Lagoa d'Anta" },
  { id: 69, name: 'Lagoa de Pedras' },
  { id: 70, name: 'Lagoa de Velhos' },
  { id: 71, name: 'Lagoa Nova' },
  { id: 72, name: 'Lagoa Salgada' },
  { id: 73, name: 'Lajes' },
  { id: 74, name: 'Lajes Pintadas' },
  { id: 75, name: 'Lucrécia' },
  { id: 76, name: 'Luís Gomes' },
  { id: 77, name: 'Macaíba' },
  { id: 78, name: 'Macau' },
  { id: 79, name: 'Major Sales' },
  { id: 80, name: 'Marcelino Vieira' },
  { id: 81, name: 'Martins' },
  { id: 82, name: 'Maxaranguape' },
  { id: 83, name: 'Messias Targino' },
  { id: 84, name: 'Montanhas' },
  { id: 85, name: 'Monte Alegre' },
  { id: 86, name: 'Monte das Gameleiras' },
  { id: 87, name: 'Mossoró' },
  { id: 88, name: 'Natal' },
  { id: 89, name: 'Nísia Floresta' },
  { id: 90, name: 'Nova Cruz' },
  { id: 91, name: "Olho d'Água do Borges" },
  { id: 92, name: 'Ouro Branco' },
  { id: 93, name: 'Paraná' },
  { id: 94, name: 'Paraú' },
  { id: 95, name: 'Parazinho' },
  { id: 96, name: 'Parelhas' },
  { id: 97, name: 'Parnamirim' },
  { id: 98, name: 'Passa e Fica' },
  { id: 99, name: 'Passagem' },
  { id: 100, name: 'Patu' },
  { id: 101, name: 'Pau dos Ferros' },
  { id: 102, name: 'Pedra Grande' },
  { id: 103, name: 'Pedra Preta' },
  { id: 104, name: 'Pedro Avelino' },
  { id: 105, name: 'Pedro Velho' },
  { id: 106, name: 'Pendências' },
  { id: 107, name: 'Pilões' },
  { id: 108, name: 'Poço Branco' },
  { id: 109, name: 'Portalegre' },
  { id: 110, name: 'Porto do Mangue' },
  { id: 111, name: 'Pureza' },
  { id: 112, name: 'Rafael Fernandes' },
  { id: 113, name: 'Rafael Godeiro' },
  { id: 114, name: 'Riacho da Cruz' },
  { id: 115, name: 'Riacho de Santana' },
  { id: 116, name: 'Riachuelo' },
  { id: 117, name: 'Rio do Fogo' },
  { id: 118, name: 'Rodolfo Fernandes' },
  { id: 119, name: 'Ruy Barbosa' },
  { id: 120, name: 'Santa Cruz' },
  { id: 121, name: 'Santa Maria' },
  { id: 122, name: 'Santana do Matos' },
  { id: 123, name: 'Santana do Seridó' },
  { id: 124, name: 'Santo Antônio' },
  { id: 125, name: 'São Bento do Norte' },
  { id: 126, name: 'São Bento do Trairí' },
  { id: 127, name: 'São Fernando' },
  { id: 128, name: 'São Francisco do Oeste' },
  { id: 129, name: 'São Gonçalo do Amarante' },
  { id: 130, name: 'São João do Sabugi' },
  { id: 131, name: 'São José de Mipibu' },
  { id: 132, name: 'São José do Campestre' },
  { id: 133, name: 'São José do Seridó' },
  { id: 134, name: 'São Miguel' },
  { id: 135, name: 'São Miguel do Gostoso' },
  { id: 136, name: 'São Paulo do Potengi' },
  { id: 137, name: 'São Pedro' },
  { id: 138, name: 'São Rafael' },
  { id: 139, name: 'São Tomé' },
  { id: 140, name: 'São Vicente' },
  { id: 141, name: 'Senador Elói de Souza' },
  { id: 142, name: 'Senador Georgino Avelino' },
  { id: 143, name: 'Serra Caiada' },
  { id: 144, name: 'Serra de São Bento' },
  { id: 145, name: 'Serra do Mel' },
  { id: 146, name: 'Serra Negra do Norte' },
  { id: 147, name: 'Serrinha' },
  { id: 148, name: 'Serrinha dos Pintos' },
  { id: 149, name: 'Severiano Melo' },
  { id: 150, name: 'Sítio Novo' },
  { id: 151, name: 'Taboleiro Grande' },
  { id: 152, name: 'Taipu' },
  { id: 153, name: 'Tangará' },
  { id: 154, name: 'Tenente Ananias' },
  { id: 155, name: 'Tenente Laurentino Cruz' },
  { id: 156, name: 'Tibau' },
  { id: 157, name: 'Tibau do Sul' },
  { id: 158, name: 'Timbaúba dos Batistas' },
  { id: 159, name: 'Touros' },
  { id: 160, name: 'Triunfo Potiguar' },
  { id: 161, name: 'Umarizal' },
  { id: 162, name: 'Upanema' },
  { id: 163, name: 'Várzea' },
  { id: 164, name: 'Venha-Ver' },
  { id: 165, name: 'Vera Cruz' },
  { id: 166, name: 'Viçosa' },
  { id: 167, name: 'Vila Flor' },
]
