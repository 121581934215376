import { Avatar, Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../data/contexts'
import { CPF } from '../../logic/utils/CPF'

interface ProfileProps {
  showProfileData?: boolean
}

export function Profile({ showProfileData }: ProfileProps) {
  const { user } = useAuth()
  const avatarSize = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' })
  const navigate = useNavigate()

  return (
    <Flex align={'center'} onClick={() => navigate('/profile')} cursor={'pointer'}>
      {showProfileData && (
        <Box mr={'4'} textAlign={'right'}>
          <Text
            color={'white'}
            fontWeight={'semibold'}
            fontSize={'md'}
            lineHeight={'1.188rem'}
          >
            {user?.nome}
          </Text>
          <Text color={'white'} fontSize={'md'} lineHeight={'1.188rem'}>
            CPF: {user && CPF.format(user.cpf)}
          </Text>
        </Box>
      )}

      <Avatar
        size={avatarSize}
        fontSize={'3rem'}
        name={user?.nome}
        bgColor={'green.50'}
        color={'white'}
      />
    </Flex>
  )
}
