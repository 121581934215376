function selectStatus(status: string) {
  const key = status.replaceAll(' ', '_').toUpperCase()

  const bgColorStatus: { [key: string]: string } = {
    PENDENTE: 'yellow.600',
    CADASTRADA: 'blue.50',
    ANÁLISE: 'yellow.800',
    CORREÇÃO: 'orange.500',
    CORRIGIDA: 'blue.500',
    DEFERIDA: 'green.50',
    INDEFERIDA: 'red.500',
  }

  return bgColorStatus[key] || 'blue.50'
}

export const useBgColorStatus = (status: string) => selectStatus(status)
