import { Stack } from '@chakra-ui/react'
import { RiOpenArmLine, RiUserLine } from 'react-icons/ri'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function SidebarNav() {
  return (
    <Stack spacing={'12'} align={'flex-start'}>
      <NavSection title={'GERAL'}>
        <NavLink href="/orphans" icon={RiOpenArmLine}>
          Órfãos
        </NavLink>
        <NavLink href="/profile" icon={RiUserLine}>
          Perfil
        </NavLink>
      </NavSection>
    </Stack>
  )
}
