import { ChakraProps, Heading } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface TitleSlideProps extends ChakraProps {
  children: ReactNode
}

export function TitleSlide({ children, ...rest }: TitleSlideProps) {
  return (
    <Heading
      as="h1"
      fontSize={{ base: 'md', md: 'lg', lg: '2xl' }}
      fontWeight={'bold'}
      lineHeight={'1.3125rem'}
      marginBottom={{ base: '0.75rem', md: '1.5rem' }}
      {...rest}
    >
      {children}
    </Heading>
  )
}
