import { Badge, BadgeProps } from '@chakra-ui/react'

type CardStatusProps = BadgeProps

export function CardStatus({ children, ...rest }: CardStatusProps) {
  return (
    <Badge
      as="header"
      display="flex"
      fontSize="0.875rem"
      justifyContent="center"
      lineHeight="1rem"
      p="0.625rem 0"
      variant="solid"
      {...rest}
    >
      {children}
    </Badge>
  )
}
