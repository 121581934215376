import { Link, LinkProps } from '@chakra-ui/react'

type FormLinkProps = LinkProps

export function FormLink({ children, ...props }: FormLinkProps) {
  const originalName = children?.toString();
  const displayName = originalName && !originalName.endsWith('.pdf') ? `${originalName}.pdf` : originalName;

  return (
    <Link
      rel="noreferrer"
      target="_blank"
      color="blue.300"
      fontSize="1.125rem"
      lineHeight="1.3125rem"
      textAlign={{
        base: 'center',
        sm: 'start',
        md: 'center',
        xl: 'start',
      }}
      {...props}
    >
      {displayName}
    </Link>
  );
}
