import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FormControlItem } from './InputBase'
import { FormInput } from './interfaces'

type SelectProps = Omit<FormInput, 'mask' | 'format' | 'isAllowed'> &
  ChakraSelectProps

const InputBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { _required = true, disabled = false, error, info, label, name, ...rest },
  ref,
) => {
  return (
    <FormControlItem
      _required={_required}
      disabled={disabled}
      error={error}
      info={info}
      label={label}
      name={name}
    >
      <ChakraSelect
        id={name}
        name={name}
        focusBorderColor={'blue.300'}
        borderColor={'black.300'}
        disabled={disabled}
        opacity={disabled ? 0.4 : 1}
        cursor={disabled ? 'not-allowed' : 'auto'}
        variant={'flushed'}
        _hover={{ borderColor: 'blue.300' }}
        size={'md'}
        ref={ref}
        {...rest}
      />
    </FormControlItem>
  )
}

export const Select = forwardRef(InputBase)
