import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  List,
  Text,
} from '@chakra-ui/react'
import { FcInfo } from 'react-icons/fc'
import { descriptionsStatusType } from '../../data/constants'
import { Subtitle } from './subtitle'

export function Subtitles() {
  return (
    <Box mb="4" p="0.5rem">
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton py="2" px="3" rounded="0.25rem">
              <Box
                flex="1"
                textAlign="left"
                display={'flex'}
                alignItems={'center'}
              >
                <FcInfo />
                <Text ml="0.25rem" fontSize="1rem">
                  Legendas
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <List spacing={3} mt={2}>
              {descriptionsStatusType.map((subtitle) => (
                <Subtitle
                  key={subtitle.id}
                  name={subtitle.type}
                  info={subtitle.description}
                />
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
