import { Box, Flex, VStack } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { BoxSlide, LinkSETHAS, TextSlide, TitleSlide } from './'
import './carousel.css'

export function CarouselLogin() {
  return (
    <Box>
      <Carousel
        showStatus={false}
        // showArrows={false}
        autoPlay={true}
        interval={6000}
        infiniteLoop={true}
        showThumbs={false}
      >
        <Flex>
          <BoxSlide>
            <VStack gap={{ base: '2rem', md: '2.75rem' }}>
              <Box>
                <TitleSlide>
                  O que motivou a criação do Programa RN Acolhe?
                </TitleSlide>
                <TextSlide>
                  Em novembro de 2021, a Secretaria de Estado do Trabalho, da
                  Habitação e da Assistência Social (SETHAS) realizou um
                  levantamento prévio, junto aos 167 municípios do estado para
                  identificar os órfãos da COVID-19. Até o momento, 95
                  municípios responderam ao cadastramento e 33 deles
                  identificaram 115 crianças e/ou adolescentes em situação de
                  orfandade.
                </TextSlide>
              </Box>
              <Box>
                <TitleSlide>A quem destina-se o Programa?</TitleSlide>
                <TextSlide>
                  O Programa destina-se a assegurar proteção social às crianças
                  e adolescentes em situação de orfandade bilateral ou de
                  família monoparental, em face da pandemia da COVID-19, sendo
                  priorizadas as crianças e os adolescentes, em situação de
                  pobreza e extrema pobreza, inseridos no Cadastro Único
                  (CadÚnico), levando em consideração a renda da família de
                  origem.
                </TextSlide>
              </Box>
              <Box>
                <TitleSlide>O que é o Programa RN Acolhe?</TitleSlide>
                <TextSlide>
                  É um programa estadual de proteção social de crianças e
                  adolescentes residentes no Estado do Rio Grande do Norte
                  vítimas da COVID-19.
                </TextSlide>
              </Box>
            </VStack>
          </BoxSlide>
        </Flex>
        <Flex>
          <BoxSlide>
            <VStack gap={'2.75rem'}>
              <Box>
                <TitleSlide>
                  Quem são os beneficiários do Programa RN Acolhe?
                </TitleSlide>
                <TextSlide>
                  Crianças e/ou adolescentes domiciliados no estado do Rio
                  Grande do Norte, há pelo menos 1 (um) ano antes da orfandade
                  bilateral ou em família monoparental. Cuja renda da família de
                  origem NÃO ultrapasse 3 (três) salários mínimos, com
                  prioridade às crianças e os adolescentes em situação de risco
                  e vulnerabilidade social, especialmente aqueles em situação de
                  pobreza e extrema pobreza, inscritos no CadÚnico.
                </TextSlide>
              </Box>
              <Box>
                <TitleSlide>
                  O que é situação de orfandade monoparental?
                </TitleSlide>
                <TextSlide>
                  A orfandade monoparental é quando a família é formada por
                  apenas um dos pais e este faleceu por COVID-19.
                </TextSlide>
              </Box>
              <Box>
                <TitleSlide>
                  O que é situação de orfandade bilateral?
                </TitleSlide>
                <TextSlide>
                  A orfandade bilateral é quando ambos os pais, biológicos ou
                  por adoção, faleceram, sendo, pelo menos um deles por
                  COVID-19.
                </TextSlide>
              </Box>
            </VStack>
          </BoxSlide>
        </Flex>
        <Flex>
          <BoxSlide>
            <TitleSlide>Quais os documentos necessários?</TitleSlide>
            <VStack gap={{ base: '0.5rem', md: '1.188rem' }}>
              <TextSlide>
                I. Cópia autenticada da certidão de nascimento da criança ou
                adolescentes, ou no caso de adoção, o documento oficial que a
                comprove;
              </TextSlide>
              <TextSlide>
                II. Cópia autenticada das certidões de óbito dos pais constantes
                do registro de nascimento;
              </TextSlide>
              <TextSlide>III. Cópia da folha resumo do CadÚnico;</TextSlide>
              <TextSlide>
                IV. Comprovante de inscrição no CPF do beneficiário;
              </TextSlide>
              <TextSlide>
                V. Certidão emitida pela instituição responsável pelo regime de
                previdência ao qual o falecido era vinculado, que ateste se há
                concessão de pensão por morte devida ao dependente e o
                respectivo valor;
              </TextSlide>
              <TextSlide>
                VI. Comprovantes de renda familiar que demonstrem que os pais ou
                responsáveis diretos não recebiam, antes de óbito, renda
                superior a 3 (três) salários mínimos;
              </TextSlide>
              <TextSlide>
                VII. Cópia do termo de responsabilidade legal emitido pelo
                Conselho Tutelar ou da guarda expedido por autoridade judiciária
                ou outro documento hábil comprobatório da guarda, tutela ou
                adoção da criança ou adolescente órfão;
              </TextSlide>
            </VStack>
          </BoxSlide>
        </Flex>
        <Flex>
          <BoxSlide>
            <TitleSlide>Quais os documentos necessários?</TitleSlide>
            <VStack gap={'1.313rem'} mb={'10rem'}>
              <TextSlide>
                VIII. Cópia autenticada de documentos de identificação do
                responsável legal (RG, CPF e comprovante de residência);
              </TextSlide>
              <TextSlide>
                IX. Preenchimento do termo de responsabilidade de comunicação
                sobre ocorrências relacionadas ao requerimento, formulado por
                meio de portal disponibilizado pela Secretaria de Estado do
                Trabalho, da Habitação e da Assistência Social (SETHAS);
              </TextSlide>
              <TextSlide>
                X. Cópia da folha resumo do CadÚnico, para fins de comprovação
                do domicílio do beneficiário.
              </TextSlide>
            </VStack>
            <LinkSETHAS />
          </BoxSlide>
        </Flex>
      </Carousel>
    </Box>
  )
}
