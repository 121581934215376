import {
  GetRequestFilteredByIdActionTypes,
  GetRequestFilteredByIdState,
} from './'

export function getRequestFilteredByIdReducer(
  state: GetRequestFilteredByIdState,
  action: GetRequestFilteredByIdActionTypes,
) {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        steps: action.steps,
        request: action.request,
        allAttachments: action.allAttachments,
        correctedAttachments: action.correctAttachments,
        loading: false,
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
