import { Text, TextProps } from '@chakra-ui/react'

type CardTitleProps = TextProps

export function CardTitle({ children, ...rest }: CardTitleProps) {
  return (
    <Text
      color="blue.500"
      fontSize="1rem"
      fontWeight="bold"
      lineHeight="1.1875rem"
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </Text>
  )
}
