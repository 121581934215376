import { DescriptionStatusType, TypeStatus } from '../../logic/core'

export const descriptionsStatusType: DescriptionStatusType[] = [
  {
    id: '1',
    type: TypeStatus.PENDENTE,
    description:
      'A solicitação necessita da adição de documentos para poder ser cadastrada no sistema do RN Acolhe.',
    bgColor: 'yellow.600',
  },
  {
    id: '2',
    type: TypeStatus.CADASTRADA,
    description:
      'A solicitação foi cadastrada no sistema do RN Acolhe e está aguardando ser analisada.',
    bgColor: 'blue.50',
  },
  {
    id: '3',
    type: TypeStatus.ANALISE,
    description:
      'A solicitação está sendo analisada e terá um resultado em breve.',
    bgColor: 'yellow.800',
  },
  {
    id: '4',
    type: TypeStatus.CORRECAO,
    description:
      'A solicitação foi analisada e foram identificados problemas com os dados ou documentos informados na solicitação (e.g. dados pessoais com erros de ortografia, documentos com má visualização, dentre outros), necessitando a correção para a solicitação ser reanalisada.',
    bgColor: 'orange.500',
  },
  {
    id: '5',
    type: TypeStatus.CORRIGIDA,
    description:
      'A solicitação teve seus dados ou documentos corrigidos de acordo com os problemas encontrados na análise e está apta a ser reanalisada.',
    bgColor: 'blue.500',
  },
  {
    id: '6',
    type: TypeStatus.DEFERIDA,
    description:
      'A solicitação foi analisada e aprovada para o recebimento do benefício do RN Acolhe.',
    bgColor: 'green.50',
  },
  {
    id: '7',
    type: TypeStatus.INDEFERIDA,
    description:
      'A solicitação foi analisada e identificou-se que ela não está dentro de um ou mais critérios para o recebimento do benefício do RN Acolhe.',
    bgColor: 'red.500',
  },
]
