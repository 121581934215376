import { FormCancelSubmission } from './FormCancelSubmission'
import { FormInput } from './FormInput'
import { FormLink } from './FormLink'
import { FormRoot } from './FormRoot'

export const Form = {
  Root: FormRoot,
  Link: FormLink,
  Input: FormInput,
  CancelSubmission: FormCancelSubmission,
}
