import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {
  GuardianFormEnderecoData,
  GuardianFormValidatorEnderecoSchema,
  services,
} from '../../../logic/core'
import { useAuth } from '../../contexts'

const { guardians } = services

export function useUpdateTutorEndereco() {
  const { user } = useAuth()
  const tutorId = user?.tutor_id || undefined

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<GuardianFormEnderecoData>({
    resolver: yupResolver(GuardianFormValidatorEnderecoSchema),
    mode: 'all',
  })

  const updateEndereco: SubmitHandler<GuardianFormEnderecoData> = async (
    values: any,
  ) => {
    const dataEndereco = {
      endereco: {
        ...values,
      },
    }
    const { data } = await guardians.update(tutorId as number, dataEndereco)
    toast.success('Tutor atualizado com sucesso!')
    return data
  }

  return {
    register,
    handleSubmit,
    setError,
    reset,
    updateEndereco,
    watch,
    errors,
    isSubmitting,
  }
}
