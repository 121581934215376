import {
  ModalBody as ChakraModalBody,
  ModalFooterProps as ChakraModalBodyProps,
} from '@chakra-ui/react'

type ModalBodyProps = ChakraModalBodyProps

export function ModalBody({ children, ...props }: ModalBodyProps) {
  return (
    <ChakraModalBody textAlign="center" {...props}>
      {children}
    </ChakraModalBody>
  )
}
