import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react'

type ContentProps = BoxProps

export function Content({ children }: ContentProps) {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <Box
      as={'main'}
      flex={'1'}
      borderRadius={isWideVersion ? '8' : '0'}
      bg={'platinum.100'}
      p={'2rem'}
    >
      {children}
    </Box>
  )
}
