import { Flex, FlexProps } from '@chakra-ui/react'

type CardContentProps = FlexProps

export function CardContent({ children, ...props }: CardContentProps) {
  return (
    <Flex
      width="100%"
      flexDirection={{
        base: 'column-reverse',
        sm: 'row',
        md: 'column-reverse',
        xl: 'row',
      }}
      alignItems="center"
      justifyContent="space-between"
      gap={{ base: '1rem', md: '2rem' }}
      {...props}
    >
      {children}
    </Flex>
  )
}
