import { Flex, Text, Link as ChakraLink } from '@chakra-ui/react'

export function FaleConosco({ onOpen }: { onOpen: () => void }) {
  return (
    <Flex gap={'1'}>
      <Text lineHeight={'1.188rem'}>Dúvidas?</Text>
      <ChakraLink
        onClick={onOpen}
        lineHeight={'1.188rem'}
        fontWeight="normal"
        fontSize="md"
        color={'blue.50'}
      >
        Fale Conosco.
      </ChakraLink>
    </Flex>
  )
}
