import { Box, Image } from '@chakra-ui/react'
import backgroundLogin from '../../../../assets/backgroundLogin.png'
import { ReactNode } from 'react'

interface BoxSlideProps {
  children: ReactNode
}

export function BoxSlide({ children }: BoxSlideProps) {
  return (
    <>
      <Image
        src={backgroundLogin}
        objectFit={'cover'}
        height={{ base: '48rem', lg: '48rem' }}
      />
      <Box
        position={'absolute'}
        color={'#312526'}
        px={{ base: '0.5rem', sm: '2rem', lg: '3rem', xl: '4.5rem' }}
        mt={{ base: '2rem', lg: '3rem', '3xl': '4rem' }}
      >
        {children}
      </Box>
    </>
  )
}
