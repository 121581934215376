import { Flex, FlexProps } from '@chakra-ui/react'

interface CardContentProps extends FlexProps {
  children: React.ReactNode
}

export function CardContent({ children, ...rest }: CardContentProps) {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      gap="0.5rem"
      ml="0.5rem"
      width="100%"
      {...rest}
    >
      {children}
    </Flex>
  )
}
