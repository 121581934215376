import { Flex, FormControl, FormControlProps, Icon, Text } from '@chakra-ui/react'
import { MdReport } from 'react-icons/md'
import { FormInput } from '../interfaces'
import {
  FormErrorMessageItem,
  FormHelperTextItem,
  FormLabelItem,
  FormLegend,
  RequiredIndicatorItem,
} from './'

type FormControlItemProps = Omit<FormInput, 'mask' | 'format' | 'isAllowed'> &
  FormControlProps

export function FormControlItem({
  _required,
  children,
  disabled,
  error,
  info,
  legend,
  label,
  name,
}: FormControlItemProps) {
  return (
    <FormControl isInvalid={!!error} isDisabled={disabled}>
      {!!label && (
        <Flex>
          <FormLabelItem htmlFor={name} disabled={disabled}>
            {label}
            {_required && <RequiredIndicatorItem>*</RequiredIndicatorItem>}
          </FormLabelItem>
          {legend && <FormLegend legendTextArray={legend} />}
        </Flex>
      )}
      {children}
      {info && !error && <FormHelperTextItem>{info}</FormHelperTextItem>}
      {!!error && (
        <FormErrorMessageItem>
          <Text as="span" alignItems="center" textAlign="start">
            <Icon as={MdReport} mr="0.25rem" mb="-0.125rem" />
            {error.message}
          </Text>
        </FormErrorMessageItem>
      )}
    </FormControl>
  )
}
