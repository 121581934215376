import { Textarea, TextareaProps } from '@chakra-ui/react'

type CardJustificationProps = TextareaProps & {
  text: string
}

export function CardJustification({ text, ...rest }: CardJustificationProps) {
  const longTextDetected = text.length > 22

  return (
    <Textarea
      variant="unstyled"
      fontSize="lg"
      fontWeight="normal"
      textAlign={{ base: 'center', sm: 'start' }}
      color="gray.400"
      resize={longTextDetected ? 'vertical' : 'none'}
      height={longTextDetected ? '54px' : '27px'}
      minHeight={longTextDetected ? '54px' : '27px'}
      maxHeight="81px"
      padding="0"
      isReadOnly
      defaultValue={`"${text}"`}
      {...rest}
    />
  )
}
