import {
  Flex,
  Grid,
  GridItem,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Textarea,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {
  FormControlItem,
  Input,
  Modal,
  ModalConfirm,
  SecondaryButton,
  SectionDivider,
  TertiaryButton,
} from '../../../../components'
import { useFaleConosco } from '../../../../data/hooks/useFaleConosco'

interface ModalFaleConoscoProps {
  isOpen: boolean
  onClose: () => void
  onOpen?: () => void
}

export function ModalFaleConosco({ isOpen, onClose }: ModalFaleConoscoProps) {
  const { isSubmitting, errors, trigger, register, handleSubmit, onSubmit } =
    useFaleConosco({
      onCloseModal: onClose,
    })
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()

  const modalSize = useBreakpointValue({ base: 'lg', md: '2xl', '3xl': '3xl' })

  const handleSubmitModal = async () => {
    await handleSubmit(onSubmit)()
    onCloseConfirm()
    if (!isSubmitting) {
      onClose()
    }
  }

  const handleConfirm = async () => {
    const valid = await trigger()

    if (valid) {
      onOpenConfirm()
    }
  }

  return (
    <>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isSubmitting={isSubmitting}
        title={'Após enviar, aguarde nosso contato!'}
        description={
          'Nossa equipe entrará em contato com você o mais rápido possível.'
        }
        onConfirm={handleSubmitModal}
      />

      <Modal.Root size={modalSize} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Modal.Header>
            <Flex>Fale Conosco</Flex>
            <ModalCloseButton />
            <SectionDivider />
          </Modal.Header>
          <Modal.Body>
            <Flex>
              <Grid
                templateAreas={{
                  base: `"nome" "cpf" "contato" "mensagem"`,
                  sm: `"nome nome" "cpf contato" "mensagem mensagem"`,
                }}
                gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr' }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'nome'}>
                  <Input
                    label="Nome Completo"
                    {...register('nome')}
                    error={errors.nome}
                  />
                </GridItem>
                <GridItem area={'cpf'}>
                  <Input
                    label="CPF"
                    mask={'999.999.999.99'}
                    placeholder="000.000.000-00"
                    {...register('cpf')}
                    error={errors.cpf}
                  />
                </GridItem>
                <GridItem area={'contato'}>
                  <Input
                    label="Telefone/Celular para contato"
                    type={'tel'}
                    mask={'(99) 99999-9999'}
                    placeholder="(00) 00000-0000"
                    {...register('contato')}
                    error={errors.contato}
                  />
                </GridItem>
                <GridItem area={'mensagem'}>
                  <FormControlItem
                    _required={true}
                    label={'Dúvida'}
                    error={errors.mensagem}
                  >
                    <Textarea
                      placeholder="Escreva sua dúvida aqui!"
                      size={'lg'}
                      resize={'none'}
                      {...register('mensagem')}
                    />
                  </FormControlItem>
                </GridItem>
              </Grid>
            </Flex>
          </Modal.Body>
          <Modal.Footer>
            <TertiaryButton onClick={onClose}>Fechar</TertiaryButton>
            <SecondaryButton
              onClick={handleConfirm}
              isLoading={isSubmitting}
              disabled={Object.keys(errors).length > 0}
            >
              Enviar
            </SecondaryButton>
          </Modal.Footer>
        </ModalContent>
      </Modal.Root>
    </>
  )
}
