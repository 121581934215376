import { Box, Stack, Text } from '@chakra-ui/react'
import { PaginationItem } from './PaginationItem'

interface PaginationProps {
  totalCountOfRegisters?: number
  registersPerPage?: number
  currentPage?: number
  onPageChange: (page: number) => void
  titlePage?: string
}

const siblingsCount = 1

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter(page => page > 0)
}

export function Pagination({
  totalCountOfRegisters = 0,
  registersPerPage = 10,
  currentPage = 1,
  onPageChange: onChange,
  titlePage,
}: PaginationProps) {
  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : []

  return (
    <Stack
      direction={['column', 'row']}
      spacing={'6'}
      mt={'8'}
      justify={'space-between'}
      alignItems={'center'}
    >
      <Box>
        Página <strong>{currentPage}</strong> - <strong>{lastPage}</strong> de{' '}
        <strong>{totalCountOfRegisters}</strong> {titlePage}
        {/* <strong>
          {Number.isInteger(totalCountOfRegisters / registersPerPage)
            ? totalCountOfRegisters / registersPerPage
            : Math.ceil(totalCountOfRegisters / registersPerPage)}
        </strong> */}
      </Box>
      <Stack direction={'row'} spacing={'2'}>
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem onPageChange={onChange} number={1} />
            {currentPage > 2 + siblingsCount && (
              <Text color={'gray.300'} width={'8'} textAlign={'center'}>
                ...
              </Text>
            )}
          </>
        )}

        {previousPages.length > 0 &&
          previousPages.map(page => (
            <PaginationItem onPageChange={onChange} key={page} number={page} />
          ))}

        <PaginationItem
          onPageChange={onChange}
          number={currentPage}
          isCurrent
        />

        {nextPages.length > 0 &&
          nextPages.map(page => (
            <PaginationItem onPageChange={onChange} key={page} number={page} />
          ))}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && (
              <Text color={'gray.300'} width={'8'} textAlign={'center'}>
                ...
              </Text>
            )}
            <PaginationItem onPageChange={onChange} number={lastPage} />
          </>
        )}
      </Stack>
    </Stack>
  )
}
