import { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { GetRequestByIdOutput } from '.'
import { services } from '../../../../logic/core'
import { useAuth } from '../../../contexts'
import {
  ACTION_TYPES,
  getRequestByIdReducer,
  INITIAL_STATE_REQUEST_BY_ID,
} from '../../../reducers'

export function useGetRequestById() {
  const { user } = useAuth()
  const tutorId = user?.tutor_id
  const { orphanId, requestId } = useParams()
  const [state, dispatch] = useReducer(
    getRequestByIdReducer,
    INITIAL_STATE_REQUEST_BY_ID,
  )

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    async function fetch() {
      dispatch({ type: ACTION_TYPES.FETCH_START })
      try {
        const config = {
          signal: signal,
        }
        const data = await services.requests.getById<GetRequestByIdOutput>({
          tutorId,
          orphanId,
          requestId,
          config,
        })
        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: data })
      } catch (requestError: any) {
        const detail = requestError?.response.data.detail as string[]
        if (detail) {
          return dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            payload: detail[0],
          })
        }
        dispatch({
          type: ACTION_TYPES.FETCH_ERROR,
          payload: 'Ocorreu um erro ao buscar os dados da solicitação.',
        })
      }
    }

    if (!!tutorId && !!orphanId && !!requestId) {
      fetch()
    }

    return () => controller.abort()
  }, [orphanId, requestId, tutorId])

  return {
    ...state,
  }
}
