import { ModalBody } from './ModalBody'
import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import { ModalRoot } from './ModalRoot'

export * from './ModalCancel'
export * from './ModalConfirm'

export const Modal = {
  Body: ModalBody,
  Footer: ModalFooter,
  Header: ModalHeader,
  Root: ModalRoot,
}
