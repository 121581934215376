import { useQuery } from 'react-query'
import { services } from '../../../logic/core'
import { useAuth } from '../../contexts'

const { guardians } = services

export function useGetTutor() {
  const { user } = useAuth()
  const tutorId = user?.tutor_id || undefined

  const getTutor = useQuery(
    ['tutor', tutorId],
    () => guardians.get(tutorId as number),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      enabled: !!tutorId,
    },
  )

  return {
    getTutor,
  }
}
