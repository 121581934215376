import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import InputMask from 'react-input-mask'
import { FormControlItem } from './InputBase'
import { FormInput } from './interfaces'

type InputProps = FormInput & ChakraInputProps

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    _required = true,
    disabled = false,
    error,
    format,
    info,
    isAllowed,
    label,
    mask,
    name,
    ...rest
  },
  ref,
) => {
  return (
    <FormControlItem
      _required={_required}
      disabled={disabled}
      label={label}
      info={info}
      error={error}
      name={name}
    >
      <ChakraInput
        as={!!mask ? InputMask : 'input'}
        id={name}
        name={name}
        mask={mask}
        format={format}
        isallowed={isAllowed}
        focusBorderColor={'blue.300'}
        borderColor={'black.300'}
        disabled={disabled}
        opacity={disabled ? 0.4 : 1}
        cursor={disabled ? 'not-allowed' : 'auto'}
        variant={'flushed'}
        _hover={{ borderColor: 'blue.300' }}
        size={'md'}
        ref={ref}
        {...rest}
      />
    </FormControlItem>
  )
}

export const Input = forwardRef(InputBase)
