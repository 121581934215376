import { Link } from '@chakra-ui/react'
import { TitleSlide } from './'

export function LinkSETHAS() {
  return (
    <>
      <TitleSlide mb={'1.5rem'}>Para mais informações, acesse:</TitleSlide>
      <Link
        fontSize={'1.125rem'}
        fontWeight={'regular'}
        lineHeight={'1.3125rem'}
        isExternal
        href={'http://www.sethas.rn.gov.br/'}
      >
        http://www.sethas.rn.gov.br/
      </Link>
    </>
  )
}
