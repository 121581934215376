import { SubmissionAttachmentsActionTypes, SubmissionAttachmentsState } from '.'

export function submissionAttachmentsReducer(
  state: SubmissionAttachmentsState,
  action: SubmissionAttachmentsActionTypes,
) {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        success: action.success,
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
        errorData: action.errorData,
      }
    case 'FETCH_FINALLY':
      return {
        ...state,
        loading: false,
        error: undefined,
        errorData: undefined,
        success: '',
      }
    default:
      return state
  }
}
