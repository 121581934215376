import { AttachFileState } from './AttachFileState'

export const INITIAL_STATE_ATTACH_FILE: AttachFileState = {
  abortController: undefined,
  error: undefined,
  errorData: undefined,
  loading: false,
  progress: 0,
  success: '',
}
