import { useQuery } from 'react-query'
import { services } from '../../../logic/core'
import { useAuth } from '../../contexts'

const { orphans } = services

export function useGetOrphans(registerPerPage = 9, currentPage = 1) {
  const { user } = useAuth()
  const tutorId = user?.tutor_id

  const getOrphan = useQuery(
    ['orphans', currentPage, registerPerPage, tutorId],
    () => orphans.get({ registerPerPage, currentPage, tutorId }),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      enabled: !!tutorId,
    },
  )

  return {
    getOrphan,
  }
}
