import { RiFileListLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import {
  CardContent,
  CardFlex,
  CardFooter,
  CardInfo,
  CardLabel,
  CardTitle,
  PrimaryButton,
} from '../../../components'
import { CPF } from '../../../logic/utils/CPF'

interface Orphan {
  id: string
  nome: string
  cpf: string
}

interface CardOrphansProps {
  orphan: Orphan
  onPrefetchOrphans?: (orphanId: string) => void
}

export function Card({ orphan }: CardOrphansProps) {
  const navigate = useNavigate()

  return (
    <CardFlex>
      <CardContent>
        <CardTitle>{orphan.nome}</CardTitle>
        <CardLabel>
          CPF:
          <CardInfo>{CPF.format(orphan.cpf)}</CardInfo>
        </CardLabel>
      </CardContent>
      <CardFooter>
        <PrimaryButton
          icon={RiFileListLine}
          onClick={() => navigate(`/orphans/${orphan.id}/requests`)}
          size="sm"
        >
          Solicitações
        </PrimaryButton>
      </CardFooter>
    </CardFlex>
  )
}
