import { Text, TextProps } from '@chakra-ui/react'

type CardLabelProps = TextProps

export function CardLabel({ children, ...rest }: CardLabelProps) {
  return (
    <Text fontWeight="bold" lineHeight="1.1875rem" {...rest}>
      {children}
    </Text>
  )
}
