import { AxiosRequestConfig } from 'axios'
import { api } from '../../../services/axios'
import { Params, Response } from '../share'
import { Request } from './Request'

interface GetRequest<C> {
  tutorId: number | undefined
  orphanId: string | undefined
  requestId: string | undefined
  config?: AxiosRequestConfig<C>
}

export class ServicesRequest {
  async get(params: Params): Promise<Response<Request>> {
    const { registerPerPage, currentPage, orphanId, tutorId } = params
    const url = `tutores/${tutorId}/orfaos/${orphanId}/solicitacoes?limit=${registerPerPage}&offset=${
      (currentPage - 1) * registerPerPage
    }`
    const {
      data: { count, results },
    } = await api.get<Response<Request>>(url)
    return { count, results }
  }

  async getFilteredById<Result>({
    tutorId,
    orphanId,
    requestId,
    config,
  }: GetRequest<any>): Promise<Result> {
    const url = `tutores/${tutorId}/orfaos/${orphanId}/solicitacoes/?id=${requestId}`
    const { data } = await api.get<Result>(url, config)
    return data
  }

  async getById<Result>({
    tutorId,
    orphanId,
    requestId,
    config,
  }: GetRequest<any>): Promise<Result> {
    const url = `tutores/${tutorId}/orfaos/${orphanId}/solicitacoes/${requestId}`
    const { data } = await api.get<Result>(url, config)
    return data
  }
}
