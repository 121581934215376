import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {
  GuardianFormPessoalData,
  GuardianFormValidatorPessoalSchema,
  services,
} from '../../../logic/core'
import { useAuth } from '../../contexts'

const { guardians } = services

interface UseUpdateTutorPessoal {
  onCloseModal: () => void
  onCloseConfirm: () => void
}

export function useUpdateTutorPessoal({
  onCloseModal,
  onCloseConfirm,
}: UseUpdateTutorPessoal) {
  const { user } = useAuth()
  const tutorId = user?.tutor_id || undefined

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<GuardianFormPessoalData>({
    resolver: yupResolver(GuardianFormValidatorPessoalSchema),
    mode: 'onChange',
  })

  const updatePessoal: SubmitHandler<GuardianFormPessoalData> = async (
    values,
  ) => {
    try {
      const response = await guardians.update(tutorId as number, values)
      toast.success('Tutor atualizado com sucesso!')
      onCloseConfirm()
      onCloseModal()
      return response
    } catch (error: any) {
      setError('email', {
        type: 'email',
        message: error?.response?.data?.mensagem,
      })
      onCloseConfirm()
      toast.error('Erro ao atualizar tutor: ' + error?.response?.data?.mensagem)
    }
  }

  return {
    register,
    handleSubmit,
    setError,
    reset,
    updatePessoal,
    watch,
    errors,
    isSubmitting,
  }
}
