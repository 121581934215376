import { ChakraProps, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface SectionSubtitleProps extends ChakraProps {
  children: ReactNode
}

export function SectionSubtitle({ children, ...rest }: SectionSubtitleProps) {
  return (
    <Text
      as={'span'}
      fontSize={'0.75rem'}
      lineHeight={'0.875rem'}
      color={'gray.400'}
      {...rest}
    >
      {children}
    </Text>
  )
}
