function selectStatus(status: string) {
  const key = status.replaceAll(' ', '_').toUpperCase()

  const colorStatus: { [key: string]: string } = {
    PENDENTE: 'black.900',
    ANÁLISE: 'black.900',
  }

  return colorStatus[key] || 'platinum.50'
}

export const useColorStatus = (status: string) => selectStatus(status)
