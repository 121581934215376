import { Heading, HeadingProps } from '@chakra-ui/react'

type CardNameProps = HeadingProps

export function CardName({ children, ...props }: CardNameProps) {
  return (
    <Heading
      size="md"
      lineHeight="1.438rem"
      textAlign={{ base: 'center', sm: 'start' }}
      {...props}
    >
      {children}
    </Heading>
  )
}
