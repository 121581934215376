import { GuardianData, GuardianFormData } from './'

export class Guardian {
  static formatGuardianData(values: GuardianFormData) {
    const signUpData: GuardianData = {
      nome: values.nome,
      cpf: values.cpf,
      data_nascimento: values.data_nascimento,
      rg: values.rg,
      orgao_expedidor: values.orgao_expedidor,
      uf_orgao_expedidor: values.uf_orgao_expedidor,
      data_expedicao: values.data_expedicao,
      email: values.email,
      telefone: values.telefone,
      telefone_alternativo: values.telefone_alternativo,

      user: {
        username: values.cpf,
        password: values.password,
      },

      endereco: {
        municipio: Number(values.municipio),
        bairro: values.bairro,
        logradouro: values.logradouro,
        numero: values.numero,
        uf: values.uf,
        cep: values.cep,
        complemento: values.complemento,
        ponto_de_referencia: values.ponto_de_referencia,
      },
    }

    return signUpData
  }
}
