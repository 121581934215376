import React from 'react'
import { Stepper } from '../Stepper'

interface TemplateStepperProps {
  array: number[]
  limit: number
}

export function TemplateStepper({ array, limit }: TemplateStepperProps) {
  const steps = Array.from({ length: limit }).map((_, index) => index + 1)

  function isActive(array: number[], value: number) {
    return array.includes(value)
  }

  return (
    <Stepper.Root>
      {steps.map((step) => {
        const currentIsActive = isActive(array, step)
        const nextIsActive = isActive(array, step + 1)

        return (
          <React.Fragment key={step}>
            <Stepper.Step
              currentIsActive={currentIsActive}
            >
              {step}
            </Stepper.Step>
            {step !== limit && (
              <Stepper.Connector
                currentIsActive={currentIsActive}
                nextIsActive={nextIsActive}
              />
            )}
          </React.Fragment>
        )
      })}
    </Stepper.Root>
  )
}
