import { AxiosResponse } from 'axios'
import { apiPublic } from '../../../services/axios'
import { getToken } from '../../../services/axios/auth'
import { Guardian, GuardianFormData } from './'
import { GuardianData } from './GuardianData'

export class ServicesGuardian {
  async create(data: GuardianFormData): Promise<AxiosResponse<any, any>> {
    const guardianData = Guardian.formatGuardianData(data)
    const url = 'tutores/'
    const response = await apiPublic.post(url, guardianData)
    return response
  }

  async get(guardianId: number): Promise<AxiosResponse<GuardianData, any>> {
    const url = `tutores/${guardianId}/`
    const response = await apiPublic.get<GuardianData>(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    return response
  }

  async update(
    guardianId: number,
    data: any,
  ): Promise<AxiosResponse<any, any>> {
    const url = `tutores/${guardianId}/`
    const response = await apiPublic.patch(url, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    return response
  }
}
