import { Stack, StackProps } from '@chakra-ui/react'

type CardContentProps = StackProps

export function CardContent({ children, ...rest }: CardContentProps) {
  return (
    <Stack
      as="main"
      height="100%"
      justifyContent="center"
      margin="1rem"
      spacing="0.75rem"
      {...rest}
    >
      {children}
    </Stack>
  )
}
