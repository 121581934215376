import { ChakraProps, Heading } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface SectionTitleProps extends ChakraProps {
  children: ReactNode
}

export function SectionTitle({ children, ...rest }: SectionTitleProps) {
  return (
    <Heading
      fontWeight={'medium'}
      fontSize={{ base: '1.125rem', md: '1.25rem' }}
      lineHeight={'1.4375rem'}
      textTransform={'uppercase'}
      {...rest}
    >
      {children}
    </Heading>
  )
}
