import {
  ButtonGroup,
  Flex,
  FlexProps,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import { RiArrowLeftLine, RiCheckboxCircleLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ButtonOutline, SecondaryButton } from '..'

interface TemplateDocumentFooterProps extends FlexProps {
  orphanId?: string
  numberOfFilesRequired?: number
  numberOfFilesNotAttached: number
  tooltipLabel: string
  onOpen: () => void
}

export function TemplateDocumentFooter({
  orphanId,
  numberOfFilesNotAttached,
  tooltipLabel,
  onOpen,
  ...props
}: TemplateDocumentFooterProps) {
  const isMobileVersion = useBreakpointValue({
    base: false,
    sm: true,
  })
  console.log('footer', numberOfFilesNotAttached)
  return (
    <Flex justifyContent="space-between" {...props}>
      <Link to={`/orphans/${orphanId}/requests`}>
        <ButtonOutline.Blue icon={RiArrowLeftLine}>Voltar</ButtonOutline.Blue>
      </Link>
      <Tooltip
        borderRadius="4"
        placement={isMobileVersion ? 'top-end' : 'top'}
        isDisabled={numberOfFilesNotAttached === 0}
        label={tooltipLabel}
      >
        <ButtonGroup
          flexDirection={['column', 'row', 'row']}
          justifyContent={['center', 'flex-end', 'flex-end']}
          isDisabled={numberOfFilesNotAttached > 0}
        >
          <SecondaryButton icon={RiCheckboxCircleLine} onClick={onOpen}>
            Enviar
          </SecondaryButton>
        </ButtonGroup>
      </Tooltip>
    </Flex>
  )
}
