import { Flex, FlexProps } from '@chakra-ui/react'

type CardRootProps = FlexProps

export function CardRoot({ children, ...props }: CardRootProps) {
  return (
    <Flex
      overflow="hidden"
      width="100%"
      padding="1rem"
      gap={{ base: '1rem', md: '1rem' }}
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      borderRadius="8px"
      boxShadow={
        '2px 2px 4px rgba(0, 0, 0, 0.25), -1px 1px 2px rgba(0, 0, 0, 0.25);'
      }
      {...props}
    >
      {children}
    </Flex>
  )
}
