import { Center, CenterProps } from '@chakra-ui/react'

interface StepProps extends CenterProps {
  currentIsActive: boolean
}

export function StepperStep({ currentIsActive, ...props }: StepProps) {
  return (
    <Center
      position="relative"
      minWidth="1.875rem"
      minHeight="1.875rem"
      borderRadius="50%"
      fontSize="1rem"
      fontWeight="bold"
      bg={currentIsActive ? 'green.50' : 'gray.100'}
      color={currentIsActive ? 'white' : 'gray.900'}
      {...props}
    >
      {props.children}
    </Center>
  )
}
