import { ServicesAttachment } from '../attachment/ServicesAttachment'
import { ServicesGuardian } from '../guardian'
import { ServicesOrphan } from '../orphan'
import { ServicesPassword } from '../password'
import { ServicesRequest } from '../request'

export const services = {
  requests: new ServicesRequest(),
  orphans: new ServicesOrphan(),
  guardians: new ServicesGuardian(),
  passwords: new ServicesPassword(),
  documents: new ServicesAttachment(),
}
