import { GetRequestByIdState } from './GetRequestByIdState'

export const INITIAL_STATE_REQUEST_BY_ID: GetRequestByIdState = {
  request: {
    id: 0,
    status: '',
    tipo_orfandade: '',
    orfao: {
      id: 0,
      sexo: '',
      fonte_beneficio: '',
      nome: '',
      cpf: '',
      nis: '',
      data_nascimento: '',
      recebe_beneficio: false,
      valor_beneficio: '',
    },
    tutor_responsavel: {
      id: 0,
      endereco: {
        municipio: '',
        logradouro: '',
        numero: '',
        bairro: '',
        complemento: '',
        ponto_de_referencia: '',
        uf: '',
        cep: '',
      },
      nome: '',
      data_nascimento: '',
      cpf: '',
      rg: '',
      data_expedicao: '',
      orgao_expedidor: '',
      uf_orgao_expedidor: '',
      email: '',
      telefone: '',
      telefone_alternativo: '',
    },
    instituicao_responsavel: null,
    falecido_um: {
      id: 0,
      fonte_beneficio: null,
      nome: '',
      cpf: '',
      numero_certidao_obito: '',
      data_emissao_certidao_obito: '',
      uf_emissao_certidao_obito: '',
      morte_por_covid: false,
      recebia_beneficio: false,
      valor_beneficio: null,
    },
    falecido_dois: null,
    anexos: [],
    parecer: '',
    created_at: '',
  },
  error: undefined,
  loading: false,
  requiredByIdDocuments: 0,
  steps: [],
}
