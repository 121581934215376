import { Flex, Image } from '@chakra-ui/react'
import logo from '../../assets/logo.png'

export function RNAcolhe() {
  return (
    <Flex
      as="header"
      align={'start'}
      justify={'start'}
      px={{ base: '1.25rem', md: '2rem' }}
      width={'100%'}
    >
      <Image
        height={{ base: '2rem', md: '3rem' }}
        src={logo}
        alt="Logo do RN Acolhe"
        objectFit={'cover'}
      />
    </Flex>
  )
}
