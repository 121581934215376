import {
  FormControl,
  FormErrorMessage,
  Icon,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Text,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { MdReport } from 'react-icons/md'
import { FormInput } from './interfaces'

type InputProps = FormInput & ChakraInputProps

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { _required = true, disabled = false, error, label, name, ...rest },
  ref,
) => {
  return (
    <FormControl
      label={label}
      isInvalid={!!error}
      isDisabled={disabled}
      justifyContent={'center'}
    >
      <ChakraInput
        type={'file'}
        accept={'application/pdf'}
        {...rest}
        ref={ref}
      />
      {!!error && (
        <FormErrorMessage
          fontSize={'md'}
          justifyContent={{
            base: 'center',
            sm: 'start',
            md: 'center',
            xl: 'start',
          }}
          lineHeight={'1.1875rem'}
          margin={'0'}
        >
          <Text
            as="span"
            alignItems={{
              base: 'center',
              sm: 'start',
              md: 'center',
              xl: 'start',
            }}
            textAlign={{
              base: 'center',
              sm: 'start',
              md: 'center',
              xl: 'start',
            }}
          >
            <Icon as={MdReport} mr="0.25rem" mb="-0.125rem" />
            {error.message}
          </Text>
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const InputFile = forwardRef(InputBase)
