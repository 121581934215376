import { ChakraProps, Divider } from '@chakra-ui/react'

interface SectionDividerProps extends ChakraProps {}

export function SectionDivider({ ...rest }: SectionDividerProps) {
  return (
    <Divider
      paddingTop={'0.4375rem'}
      borderBottom={'1px dashed #797D9A'}
      opacity={'0.4'}
      {...rest}
    />
  )
}
