import { useDisclosure } from '@chakra-ui/hooks'
import {
  Box,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { RiInformationFill } from 'react-icons/ri'

export type Legend = {
  palavraChave: string
  text: string
}

interface FormLegendProps {
  legendTextArray: Legend[]
}

export function FormLegend({ legendTextArray }: FormLegendProps) {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <Box position={'relative'} marginLeft={'0.25rem'}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        trigger="hover"
      >
        <PopoverTrigger>
          <Icon
            as={RiInformationFill}
            color={'blue.300'}
            fontSize={'1.25rem'}
          />
        </PopoverTrigger>
        <PopoverContent
          border="0"
          position={'absolute'}
          top={{
            base: '-7.5rem',
            sm: '-3.15rem',
            md: '-2.5rem',
            xl: '-0.4rem',
          }}
          left={{ base: '-5.5rem', sm: '1.5rem' }}
          maxWidth={{
            base: '200px',
            sm: '250px',
            md: '450px',
            xl: 'max-content',
          }}
        >
          <PopoverBody
            borderRadius={'0.25rem'}
            border="0"
            bg={'platinum.300'}
            color={'black.900'}
            fontSize={'md'}
            padding={'0.125rem 0.25rem 0.125rem 0.25rem'}
            minWidth={{ base: '200px', md: '450px', xl: 'max-content' }}
          >
            {legendTextArray.map((legenda) => (
              <Text
                fontSize={'0.75rem'}
                lineHeight={'0.875rem'}
                key={legenda.palavraChave}
              >
                {legenda.text.split(legenda.palavraChave)[0]}
                <Text as={'b'}>{legenda.palavraChave}</Text>
                {legenda.text.split(legenda.palavraChave)[1]}
              </Text>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
