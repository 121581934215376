import * as yup from 'yup'

export const GuardianFormValidatorEnderecoSchema = yup.object().shape({
  municipio: yup
    .string()
    .required('Município é obrigatório')
    .transform((value) => value.replace(/\D/g, '')),
  bairro: yup
    .string()
    .required('Bairro é obrigatório')
    .min(2, 'Bairro deve ter no mínimo 3 caracteres')
    .max(30, 'Bairro deve ter no máximo 30 caracteres'),
  logradouro: yup
    .string()
    .required('Logradouro é obrigatório')
    .max(50, 'Logradouro deve ter no máximo 50 caracteres'),
  numero: yup
    .string()
    .required('Número é obrigatório')
    .min(1, 'Número deve ter no mínimo 1 caracteres')
    .max(30, 'Número deve ter no máximo 30 caracteres'),
  uf: yup.string().default('RN'),
  cep: yup
    .string()
    .required('CEP é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CEP deve conter apenas números')
    .min(8, 'CEP deve ter no mínimo 8 caracteres')
    .max(8, 'CEP deve ter no máximo 8 caracteres'),
  complemento: yup
    .string()
    .max(50, 'Complemento deve ter no máximo 3 caracteres')
    .optional(),
  ponto_de_referencia: yup
    .string()
    .max(50, 'Ponto de referência deve ter no máximo 3 caracteres')
    .optional(),
})
