import { Text, TextProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface CardInfoProps extends TextProps {
  children: ReactNode
}

export function CardInfo({ children, ...rest }: CardInfoProps) {
  return (
    <Text as="span" fontWeight="normal" ml="0.5rem" {...rest}>
      {children}
    </Text>
  )
}
