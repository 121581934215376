import { Text, TextProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface CardTitleProps extends TextProps {
  children: ReactNode
}

export function CardTitle({ children, ...rest }: CardTitleProps) {
  return (
    <Text fontSize="1.25rem" fontWeight="bold">
      Status:
      <Text
        as="span"
        fontSize="1rem"
        fontWeight="normal"
        textTransform="uppercase"
        ml="0.5rem"
        {...rest}
      >
        {children}
      </Text>
    </Text>
  )
}
