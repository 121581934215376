import * as yup from 'yup'

export const OrphanFormValidatorSchema = yup.object().shape({
  nome: yup
    .string()
    .required('Nome completo é obrigatório')
    .min(2, 'Nome deve ter no mínimo 2 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CPF deve conter apenas números')
    .min(11, 'CPF deve ter no mínimo 11 caracteres')
    .max(11, 'CPF deve ter no máximo 11 caracteres'),
  nis: yup
    .string()
    .required('NIS é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'NIS deve conter apenas números')
    .min(11, 'NIS deve ter no mínimo 11 caracteres')
    .max(11, 'NIS deve ter no máximo 11 caracteres'),
  sexo: yup.string().required('Gênero é obrigatório').nullable(),
  data_nascimento: yup.string().required('Data de nascimento é obrigatória'),
  recebe_beneficio: yup
    .string()
    .required('Recebe benefício é obrigatório')
    .nullable(),
  fonte_beneficio: yup.string().when('recebe_beneficio', {
    is: 'S',
    then: yup.string().required('Fonte do benefício é obrigatório'),
  }),
  valor_beneficio: yup.string().when('recebe_beneficio', {
    is: 'S',
    then: yup.string().required('Valor do benefício é obrigatório'),
  }),
})
