import { Textarea, TextareaProps } from '@chakra-ui/react'

type CardJustificationProps = TextareaProps & {
  text: string
}

export function CardJustification({ text, ...rest }: CardJustificationProps) {
  const longTextDetected = text.length > 28
  
  return (
    <Textarea
      variant="unstyled"
      fontSize="0.875rem"
      resize={longTextDetected ? 'vertical' : 'none'}
      height={longTextDetected ? '2.625rem' : '1.3125rem'}
      minHeight={longTextDetected ? '2.625rem' : '1.3125rem'}
      maxHeight="3.9375rem"
      padding="0"
      isReadOnly
      defaultValue={`"${text}"`}
      {...rest}
    />
  )
}
