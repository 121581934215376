import { ChakraProps, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface RequiredIndicatorItemProps extends ChakraProps {
  children: ReactNode
}

export function RequiredIndicatorItem({
  children,
}: RequiredIndicatorItemProps) {
  return (
    <Text as={'span'} margin={'0 0 0 0.5rem'} color="red.500">
      {children}
    </Text>
  )
}
