import { Progress, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { RiCloseCircleLine } from 'react-icons/ri'
import { ButtonOutline } from '../../ButtonOutline'

interface FormCancelSubmissionProps {
  nameFile: string
  label: string
  progress: number
  handleCancelRequest: () => void
}

export function FormCancelSubmission(props: FormCancelSubmissionProps) {
  const [isCanceled, setIsCanceled] = useState(false)
  const progressColor = () => {
    if (isCanceled) {
      return 'red'
    }
    if (props.progress >= 95) {
      return 'green'
    }
    return 'blue'
  }
  return (
    <>
      <VStack
        alignItems={'flex-start'}
        maxW={{
          base: '15rem',
          sm: '11rem',
          md: '15rem',
          lg: '10rem',
          xl: '15rem',
          '2xl': '17rem',
        }}
        w={'100%'}
      >
        <Text
          color={'gray.400'}
          fontSize={'18px'}
          lineHeight={'21px'}
          maxW={{
            base: '15rem',
            sm: '11rem',
            md: '15rem',
            lg: '10rem',
            xl: '15rem',
            '2xl': '17rem',
          }}
        >
          {props.nameFile !== '' ? props.nameFile : props.label}
        </Text>
        <Progress
          value={props.progress}
          size="md"
          w={'100%'}
          height={'0.625rem'}
          borderRadius={'0.25rem'}
          colorScheme={progressColor()}
        />
      </VStack>
      <ButtonOutline.Red
        icon={RiCloseCircleLine}
        onClick={() => {
          props.handleCancelRequest()
          setIsCanceled(true)
        }}
        disabled={props.progress >= 95 || isCanceled}
        minWidth="8.125rem"
      >
        Cancelar
      </ButtonOutline.Red>
    </>
  )
}
