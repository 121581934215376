import * as yup from 'yup'
import { Document } from '../../utils'

export const AttachmentFormValidatorSchema = yup.object().shape({
  arquivo: yup
    .mixed()
    .test(
      'fileSize',
      'Adicione um arquivo pdf',
      (value: string | any[]) => value.length > 0,
    )
    .test(
      'maxSize',
      'Tamanho máximo por arquivo: 1MB',
      (value): boolean => Document.validateSize(value),
    ),
})
