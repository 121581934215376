import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { RiCloseCircleLine } from 'react-icons/ri'

interface TertiaryButtonProps extends ButtonProps {
  icon?: IconType
}

export function TertiaryButton({
  children,
  icon = RiCloseCircleLine,
  ...rest
}: TertiaryButtonProps) {
  return (
    <Button
      _hover={{ bgColor: 'platinum.900' }}
      bgColor="platinum.600"
      border="1px solid #E7E6E6"
      color="black.900"
      leftIcon={<Icon as={icon} />}
      rounded="0.25rem"
      size="md"
      {...rest}
    >
      {children}
    </Button>
  )
}
