import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  Input,
  ModalCancel,
  Password,
  PrimaryButton,
  SectionDivider,
  SectionForm,
  SectionSubtitle,
  SectionTitle,
  Select,
  TertiaryButton,
} from '../../components'
import { counties, districts } from '../../data/constants'
import { useAuth } from '../../data/contexts'
import { useGuardian } from '../../data/hooks'

export function SignUp() {
  const { isAuthenticated } = useAuth()
  const { errors, handleSignUp, handleSubmit, isSubmitting, register } =
    useGuardian()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  function handleConfirmCancel() {
    onClose()
    navigate('/')
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  document.title = 'RN-ACOLHE - Cadastre-se'

  if (isAuthenticated) {
    return <Navigate to="/orphans" replace={true} />
  }
  return (
    <Box>
      <ModalCancel
        isOpen={isOpen}
        onClose={onClose}
        confirmCancel={handleConfirmCancel}
      >
        Cancelar cadastro de tutor!
      </ModalCancel>

      <Flex
        w={'100%'}
        my={isWideVersion ? 6 : 0}
        maxWidth={1480}
        mx={'auto'}
        px={isWideVersion ? 6 : 0}
      >
        <Box
          as={'form'}
          flex={'1'}
          borderRadius={isWideVersion ? 8 : 0}
          bg={'platinum.100'}
          p={['6', '8']}
          onSubmit={handleSubmit(handleSignUp)}
        >
          <Heading
            fontSize={{ base: '1.5rem', md: '1.875rem' }}
            fontWeight={'normal'}
          >
            Cadastre-se
          </Heading>

          <Flex flexDirection={'column'}>
            <SectionForm>
              <SectionTitle>Dados de pessoais</SectionTitle>
              <SectionSubtitle>Dados pessoais do tutor</SectionSubtitle>
              <SectionDivider />
            </SectionForm>

            <Grid
              templateAreas={{
                base: `"nome" "cpf" "orgao-expedidor-rg" "numero-rg"`,
                sm: `"nome cpf" "orgao-expedidor-rg numero-rg"`,
              }}
              gridTemplateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: '65.887% 1fr',
              }}
              gap={'2rem'}
              marginBottom={'2rem'}
              w={'100%'}
            >
              <GridItem area={'nome'}>
                <Input
                  label={'Nome completo'}
                  type="text"
                  {...register('nome')}
                  placeholder="Digite o seu nome completo"
                  error={errors.nome}
                />
              </GridItem>

              <GridItem area={'cpf'}>
                <Input
                  label={'CPF'}
                  type="text"
                  mask={'999.999.999-99'}
                  placeholder="000.000.000-00"
                  {...register('cpf')}
                  error={errors.cpf}
                />
              </GridItem>

              <GridItem area={'orgao-expedidor-rg'}>
                <Input
                  label="Órgão expedidor do RG"
                  type="text"
                  {...register('orgao_expedidor')}
                  placeholder="Digite o órgão expedidor do RG"
                  error={errors.orgao_expedidor}
                />
              </GridItem>

              <GridItem area={'numero-rg'}>
                <Input
                  label={'Número do RG'}
                  type="text"
                  mask={'99.999.999-9'}
                  placeholder="00.000.000.0"
                  {...register('rg')}
                  error={errors.rg}
                />
              </GridItem>
            </Grid>

            <Grid
              templateAreas={{
                base: `"uf-orgao-expedidor-rg" "data-expedicao-rg" "data-nascimento" "email" "telefone" "telefone-alternativo"`,
                sm: `"uf-orgao-expedidor-rg data-expedicao-rg" "data-nascimento email" "telefone telefone-alternativo"`,
                md: `"uf-orgao-expedidor-rg data-expedicao-rg data-nascimento" "email telefone telefone-alternativo"`,
              }}
              gridTemplateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
              gap={'2rem'}
              w={'100%'}
            >
              <GridItem area={'uf-orgao-expedidor-rg'}>
                <Select
                  label={'UF do órgão expedidor do RG'}
                  {...register('uf_orgao_expedidor')}
                  error={errors.uf_orgao_expedidor}
                >
                  <option value="">--Selecione--</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.acronym}>
                      {district.name}
                    </option>
                  ))}
                </Select>
              </GridItem>

              <GridItem area={'data-expedicao-rg'}>
                <Input
                  label={'Data de expedição do RG'}
                  type="date"
                  {...register('data_expedicao')}
                  error={errors.data_expedicao}
                />
              </GridItem>

              <GridItem area={'data-nascimento'}>
                <Input
                  label={'Data de nascimento'}
                  type="date"
                  {...register('data_nascimento')}
                  error={errors.data_nascimento}
                />
              </GridItem>

              <GridItem area={'email'}>
                <Input
                  label={'E-mail'}
                  type="email"
                  placeholder="Ex: usuario@email.com"
                  {...register('email')}
                  error={errors.email}
                />
              </GridItem>

              <GridItem area={'telefone'}>
                <Input
                  label={'Telefone/Celular'}
                  type="tel"
                  mask={'(99) 99999-9999'}
                  placeholder="(00) 00000-0000"
                  {...register('telefone')}
                  error={errors.telefone}
                />
              </GridItem>

              <GridItem area={'telefone-alternativo'}>
                <Input
                  label={'Telefone/Celular alternativo'}
                  _required={false}
                  type="tel"
                  mask={'(99) 99999-9999'}
                  placeholder="(00) 00000-0000"
                  {...register('telefone_alternativo')}
                  error={errors.telefone_alternativo}
                />
              </GridItem>
            </Grid>

            <SectionForm>
              <SectionTitle>Dados de endereço</SectionTitle>
              <SectionSubtitle>Dados de endereço do tutor</SectionSubtitle>
              <SectionDivider />
            </SectionForm>

            <Grid
              templateAreas={{
                base: `"cep" "municipio" "bairro" "logradouro" "numero" "uf"`,
                sm: `"cep municipio" "bairro logradouro" "numero uf"`,
                md: `"cep municipio bairro" "logradouro numero uf"`,
              }}
              gridTemplateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
              gap={'2rem'}
              marginBottom={'2rem'}
              w={'100%'}
            >
              <GridItem area={'cep'}>
                <Input
                  label={'CEP'}
                  type="text"
                  mask={'99999-999'}
                  placeholder="00000-000"
                  {...register('cep')}
                  error={errors.cep}
                />
              </GridItem>

              <GridItem area={'municipio'}>
                <Select
                  label={'Município'}
                  {...register('municipio')}
                  error={errors.municipio}
                >
                  <option value="">--Selecione--</option>
                  {counties
                    .map((county) => (
                      <option key={county.id} value={county.id}>
                        {county.name}
                      </option>
                    ))
                    .sort()}
                </Select>
              </GridItem>

              <GridItem area={'bairro'}>
                <Input
                  label={'Bairro'}
                  type="text"
                  placeholder="Ex: Tirol"
                  {...register('bairro')}
                  error={errors.bairro}
                />
              </GridItem>

              <GridItem area={'logradouro'}>
                <Input
                  label={'Logradouro'}
                  type="text"
                  placeholder="Ex: Rua, Avenida..."
                  {...register('logradouro')}
                  error={errors.logradouro}
                />
              </GridItem>

              <GridItem area={'numero'}>
                <Input
                  label={'Número'}
                  type="text"
                  placeholder="N° 000"
                  {...register('numero')}
                  error={errors.numero}
                />
              </GridItem>

              <GridItem area={'uf'}>
                <Input
                  disabled
                  _required={false}
                  label={'UF'}
                  type="text"
                  value={'RN'}
                  {...register('uf')}
                  error={errors.uf}
                />
              </GridItem>
            </Grid>

            <Grid
              templateAreas={{
                base: `"complemento" "ponto-de-referencia"`,
                sm: `"complemento ponto-de-referencia"`,
              }}
              gridTemplateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              gap={'2rem'}
              w={'100%'}
            >
              <GridItem area={'complemento'}>
                <Input
                  label={'Complemento'}
                  _required={false}
                  type="text"
                  placeholder="Ex: Casa, Apartamento..."
                  {...register('complemento')}
                  error={errors.complemento}
                />
              </GridItem>

              <GridItem area={'ponto-de-referencia'}>
                <Input
                  label={'Ponto de referência'}
                  _required={false}
                  type="text"
                  {...register('ponto_de_referencia')}
                  error={errors.ponto_de_referencia}
                />
              </GridItem>
            </Grid>

            <SectionForm>
              <SectionTitle>Dados de acesso</SectionTitle>
              <SectionSubtitle>Dados de acesso do tutor</SectionSubtitle>
              <SectionDivider />
            </SectionForm>

            <Grid
              templateAreas={{
                base: `"senha" "confirmacao-de-senha"`,
                sm: `"senha confirmacao-de-senha"`,
              }}
              gridTemplateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              gap={'2rem'}
              w={'100%'}
            >
              <GridItem area={'senha'}>
                <Password
                  label={'Senha'}
                  placeholder="Digite uma senha"
                  {...register('password')}
                  error={errors.password}
                />
              </GridItem>

              <GridItem area={'confirmacao-de-senha'}>
                <Password
                  label={'Confirmação da senha'}
                  {...register('confirmation_password')}
                  error={errors.confirmation_password}
                />
              </GridItem>
            </Grid>
          </Flex>

          <Flex mt={'3rem'} justify={'flex-end'}>
            <HStack spacing={'4'}>
              <TertiaryButton onClick={onOpen}>Cancelar</TertiaryButton>
              <PrimaryButton
                isLoading={isSubmitting}
                loadingText={'Salvando'}
                type="submit"
              >
                Salvar
              </PrimaryButton>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
