import { useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { services } from '../../../logic/core'
import {
  ACTION_TYPES,
  INITIAL_STATE_SUBMISSION_ATTACHMENTS,
  submissionAttachmentsReducer,
} from '../../reducers'

const ERROR_DEFAULT_MESSAGE =
  'Ocorreu um erro ao confirmar o envio dos anexos. Verifique os arquivos inseridos e tente novamente.'

export function useSubmissionAttachments() {
  const { requestId } = useParams()
  const [state, dispatch] = useReducer(
    submissionAttachmentsReducer,
    INITIAL_STATE_SUBMISSION_ATTACHMENTS,
  )

  async function execute(): Promise<void> {
    dispatch({ type: ACTION_TYPES.FETCH_START })
    try {
      const mensagem = await services.documents.submissionAttachments(requestId)
      dispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        success: `${mensagem}`,
      })
    } catch (errorAttachmentConfirmation: any) {
      if (errorAttachmentConfirmation?.response) {
        if (
          errorAttachmentConfirmation?.response.status === 400 ||
          errorAttachmentConfirmation?.response.status === 404
        ) {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            error: undefined,
            errorData: errorAttachmentConfirmation?.response.data,
          })
        } else {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            error: ERROR_DEFAULT_MESSAGE,
            errorData: undefined,
          })
        }
      } else {
        dispatch({
          type: ACTION_TYPES.FETCH_ERROR,
          error: ERROR_DEFAULT_MESSAGE,
          errorData: undefined,
        })
      }
    } finally {
      dispatch({ type: ACTION_TYPES.FETCH_FINALLY })
    }
  }

  return {
    execute,
    ...state,
  }
}
