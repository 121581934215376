import { Text, TextProps } from '@chakra-ui/react'

type CardRequiredFieldProps = TextProps

export function CardRequiredField({
  children,
  ...props
}: CardRequiredFieldProps) {
  return (
    <Text as="label" marginLeft="0.2rem" color="red.500" {...props}>
      {children}
    </Text>
  )
}
