import { useDisclosure } from '@chakra-ui/react'
import { RiEyeLine, RiFileAddLine, RiFileEditLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { Card, PrimaryButton } from '../../../components'
import {
  useBgColorStatus,
  useColorStatus,
} from '../../../data/hooks'
import { Request, TypeStatus } from '../../../logic/core'
import { Data } from '../../../logic/utils/Data'
import { ModalJustification } from './ModalJustification'

interface CardRequestProps {
  request: Request
}

export function CardRequest({ request }: CardRequestProps) {
  const bgColorStatus = useBgColorStatus(request.status)
  const colorStatus = useColorStatus(request.status)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const ShowButtonAttachDocumentation =
    request.status === TypeStatus.PENDENTE
  const ShowButtonFixRequest = request.status === TypeStatus.CORRECAO
  const ShowButtonViewPurport = !!request.anexos
  const showFooter =
    ShowButtonAttachDocumentation ||
    ShowButtonFixRequest ||
    ShowButtonViewPurport

  return (
    <>
      <ModalJustification
        isOpen={isOpen}
        onClose={onClose}
        requestData={request}
      />
      <Card.Flex>
        <Card.Status bgColor={bgColorStatus} color={colorStatus}>
          {request.status}
        </Card.Status>
        <Card.Content>
          <Card.Title>{request.nome}</Card.Title>
          <Card.Label>
            Município:
            <Card.Info>{request.municipio}</Card.Info>
          </Card.Label>
          <Card.Label>
            Solicitado em:
            <Card.Info>{Data.format(request.created_at)}</Card.Info>
          </Card.Label>
        </Card.Content>

        {showFooter && (
          <Card.Footer>
            {ShowButtonAttachDocumentation && (
              <PrimaryButton
                size="sm"
                icon={RiFileAddLine}
                onClick={() => navigate(`${request.id}/documents`)}
              >
                Anexar documentação
              </PrimaryButton>
            )}

            {ShowButtonFixRequest && (
              <PrimaryButton
                size="sm"
                icon={RiFileEditLine}
                onClick={() => navigate(`${request.id}/fix`)}
              >
                Corrigir Solicitação
              </PrimaryButton>
            )}

            {!ShowButtonFixRequest && ShowButtonViewPurport && (
              <PrimaryButton size="sm" icon={RiEyeLine} onClick={onOpen}>
                Visualizar parecer
              </PrimaryButton>
            )}
          </Card.Footer>
        )}
      </Card.Flex>
    </>
  )
}
