import { Box, BoxProps } from '@chakra-ui/react'

interface ConnectorProps extends BoxProps {
  nextIsActive: boolean
  currentIsActive: boolean
}

export function StepperConnector({
  nextIsActive,
  currentIsActive,
  ...props
}: ConnectorProps) {
  return (
    <Box
      height="0.25rem"
      maxW="3.125rem"
      width="100%"
      bg={currentIsActive && nextIsActive ? 'green.50' : 'gray.100'}
      {...props}
    />
  )
}
