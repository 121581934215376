import { Textarea, TextareaProps } from '@chakra-ui/react'

type ModalOpinionProps = TextareaProps & {
  text: string
}

export function ModalOpinion({ text, ...rest }: ModalOpinionProps) {
  const longTextDetected = text.length > 29
  
  return (
    <Textarea
      variant="unstyled"
      fontSize="1rem"
      resize={longTextDetected ? 'vertical' : 'none'}
      height={longTextDetected ? '3rem' : '1.5rem'}
      minHeight={longTextDetected ? '3rem' : '1.5rem'}
      maxHeight="6rem"
      padding="0 0.5rem 0 0.5rem"
      isReadOnly
      defaultValue={`"${text}"`}
      {...rest}
    />
  )
}