import { useReducer, useState } from 'react'
import { services } from '../../../logic/core'
import { useAttachments } from '../../../data/contexts'
import {
  ACTION_TYPES,
  attachFileReducer,
  INITIAL_STATE_ATTACH_FILE,
} from '../../reducers'
import { toast } from 'react-toastify'

const ERROR_MESSAGES = {
  canceled: 'O envio do anexo foi cancelado pelo usuário.',
  default:
    'Ocorreu um erro no envio do anexo. Verifique o arquivo inserido e tente novamente.',
}

interface FileInitialState {
  id: number | null
  name: string
  url: string
}

const updatedFileInitialState:FileInitialState = {
  id: null,
  name: '',
  url: '',
}

export function useAttachFile() {
  // estado utilizado para controlar o estado do arquivo anexado, afim de nao ser necessaria a atualização da pagina
  const [updatedFile, setUpdatedFile] = useState<FileInitialState>(updatedFileInitialState)
  const [progress, setProgress] = useState(0)
  const [state, dispatch] = useReducer(
    attachFileReducer,
    INITIAL_STATE_ATTACH_FILE,
  )

  const getAttachments = useAttachments()

  async function handleFileAttachment(
    formData: FormData,
    attachmentId?: number,
  ): Promise<void> {
    const controller = new AbortController()
    const { signal } = controller
    dispatch({ type: ACTION_TYPES.FETCH_START, abortController: controller })
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        signal: signal,
      }
      setProgress(0)
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 1)
      }, 20)
      await new Promise((resolve) => setTimeout(resolve, 2000)).finally(() => {
        clearInterval(interval)
      })
      if (attachmentId) {
        setProgress(100)
        const response = await services.documents.editAttachment({
          attachmentId,
          formData,
          config,
        })
        setUpdatedFile({
          id: attachmentId,
          name: response.documento.nome_original,
          url: response.documento.arquivo
        })
        toast.success(`${response.mensagem}`)
        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, success: `${response.mensagem}` })
      } else if (updatedFile.id) {
        setProgress(100)
        const response = await services.documents.editAttachment({
          attachmentId:updatedFile.id,
          formData,
          config,
        })
        setUpdatedFile(prevState => ({
          ...prevState,
          name: response.documento.nome_original,
          url: response.documento.arquivo}))
        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, success: `${response.mensagem}` })
        toast.success(`${response.mensagem}`)
      }else {
        const response = await services.documents.uploadAttachment({
          formData,
          config,
        })
        setProgress(100)
        setUpdatedFile({
          id: response?.documento.id,
          name: response?.documento.nome_original,
          url: response?.documento.arquivo
        })

        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, success: `${response.mensagem}` })
        toast.success(`${response.mensagem}`)
        if (formData.get('tipo') !== '5') {
          getAttachments.setDocumentsAttached(getAttachments.documentsAttached + 1)
        }
        getAttachments.setArrayAttachments([...getAttachments.arrayAttachments, Number(formData.get('tipo'))])
      }
    } catch (errorAttachDocument: any) {
      if (errorAttachDocument.message === 'canceled') {
        if(!updatedFile.id) setUpdatedFile(updatedFileInitialState)
        dispatch({
          type: ACTION_TYPES.FETCH_ERROR,
          error: ERROR_MESSAGES['canceled'],
          errorData: undefined,
        })
        toast.error(ERROR_MESSAGES['canceled'])
      } else if (errorAttachDocument?.response) {
        if (
          errorAttachDocument?.response.status === 400 ||
          errorAttachDocument?.response.status === 404
        ) {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            errorData: errorAttachDocument?.response.data,
            error: undefined,
          })
          toast.error(ERROR_MESSAGES['default'])
        } else {
          dispatch({
            type: ACTION_TYPES.FETCH_ERROR,
            error: ERROR_MESSAGES['default'],
            errorData: undefined,
          })
          toast.error(ERROR_MESSAGES['default'])
        }
        setUpdatedFile(updatedFileInitialState)
        getAttachments.execute()
      } else {
        dispatch({
          type: ACTION_TYPES.FETCH_ERROR,
          error: ERROR_MESSAGES['default'],
          errorData: undefined,
        })
        toast.error(ERROR_MESSAGES['default'])
        setUpdatedFile(updatedFileInitialState)
        getAttachments.execute()
      }
    } finally {
      dispatch({ type: ACTION_TYPES.FETCH_FINALLY })
    }
  }

  function handleFileAttachmentAbort() {
    if (!state.abortController) return
    state.abortController.abort()
    setProgress(100)
  }

  return {
    ...state,
    handleFileAttachment,
    handleFileAttachmentAbort,
    progress,
    updatedFile,
    setUpdatedFile,
  }
}
