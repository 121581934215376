export class CPF {
  private static REGEX_FORMAT = /(\d{3})(\d{3})(\d{3})(\d{2})/
  private static REGEX_UN_FORMAT = /[.-]/g

  static format(cpf: string): string {
    return cpf.replace(CPF.REGEX_FORMAT, '$1.$2.$3-$4')
  }

  static unFormat(cpf: string): string {
    return cpf.replace(CPF.REGEX_UN_FORMAT, '')
  }
}
