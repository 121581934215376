import { Flex, Heading, SimpleGrid, Spinner } from '@chakra-ui/react'
import { RiAddLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import {
  AlertComponent,
  Content,
  Pagination,
  SecondaryButton,
} from '../../components'
import { useGetOrphans } from '../../data/hooks'
import { useOrphansPageStore } from '../../data/store'
import { Card } from './components/Card'

export function OrphanList() {
  const navigate = useNavigate()
  const { currentPage, setCurrentPage } = useOrphansPageStore()
  const registerPerPage = 9
  const { getOrphan } = useGetOrphans(registerPerPage, currentPage)

  function showPagination(count: number) {
    return count > 9 && registerPerPage === 9
  }

  document.title = 'RN-ACOLHE - Órfãos'

  return (
    <Content>
      <Flex mb={'3rem'} justify={'space-between'} align={'center'}>
        <Heading size={'lg'} fontWeight={'normal'} lineHeight={'2.188rem'}>
          Órfãos
          {!getOrphan.isLoading && getOrphan.isFetching && (
            <Spinner size={'sm'} color={'blue.300'} ml={'1rem'} />
          )}
        </Heading>

        <SecondaryButton
          icon={RiAddLine}
          onClick={() => navigate('/orphans/create')}
          size="sm"
        >
          Cadastrar órfão
        </SecondaryButton>
      </Flex>

      {getOrphan.isLoading ? (
        <Flex color={'blue.300'} justify={'center'} align={'center'}>
          <Spinner />
        </Flex>
      ) : getOrphan.error ? (
        <AlertComponent
          status="error"
          title="Ocorreu um erro"
          description={'Não foi possível listar os órfãos.'}
        />
      ) : !getOrphan.data?.results.length ? (
        <AlertComponent
          status={'warning'}
          title={'Nenhum órfão encontrado'}
          description={`Não encontramos nenhum órfão cadastrado. 
        Você pode cadastrar um novo órfão clicando no botão Cadastrar órfão.`}
        />
      ) : (
        <>
          <SimpleGrid
            flex={'1'}
            gap={'4'}
            minChildWidth={{ base: 'fit-content', sm: '300px' }}
          >
            {getOrphan.data?.results.map((orphan) => (
              <Card key={orphan.id} orphan={orphan} />
            ))}
          </SimpleGrid>

          {getOrphan.data?.results.length !== undefined &&
            getOrphan.data?.results.length !== 0 && (
              <>
                {showPagination(getOrphan.data.count) && (
                  <Pagination
                    totalCountOfRegisters={getOrphan.data.count}
                    registersPerPage={registerPerPage}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    titlePage={'órfãos'}
                  />
                )}
              </>
            )}
        </>
      )}
    </Content>
  )
}
