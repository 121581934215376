import { Badge, ListItem, Text } from '@chakra-ui/react'
import { useBgColorStatus, useColorStatus } from '../../data/hooks'

interface SubtitleProps {
  name: string
  info: string
}

export function Subtitle({ name, info }: SubtitleProps) {
  return (
    <ListItem>
      <Text fontSize="sm">
        <Badge
          rounded="full"
          mr="1"
          bg={useBgColorStatus(name)}
          color={useColorStatus(name)}
        >
          {name}
        </Badge>
        {info}
      </Text>
    </ListItem>
  )
}
