import {
  Button,
  Flex,
  Link as ChakraLink,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Link, Navigate } from 'react-router-dom'
import {
  Input,
  Password,
  RNAcolhe,
  RNSethas,
  SubTitle,
  Title,
} from '../../../../components'
import { useAuth } from '../../../../data/contexts'
import { SocialMedia, Subscribe } from './'
import { useLogin } from '../../../../data/hooks'
import { ModalFaleConosco } from './ModalFaleConosco'
import { FaleConosco } from './FaleConosco'

export function Login() {
  const { isAuthenticated } = useAuth()

  const { register, handleSubmit, handleSignIn, errors, isSubmitting } =
    useLogin()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (isAuthenticated) {
    return <Navigate to="/orphans" replace={true} />
  }

  return (
    <>
      <ModalFaleConosco isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

      <Flex
        width={{ base: '100%', lg: '28.7rem' }}
        height={{
          base: '100vh',
          sm: '48rem',
        }}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
        py={{ base: '1rem', md: '2rem' }}
        bg={'platinum.100'}
        borderRadius={{ base: '0rem', lg: '0.5rem 0rem 0rem 0.5rem' }}
      >
        <RNAcolhe />

        <Stack
          as="main"
          width={{ base: '100%', sm: '17.4375rem' }}
          px={{ base: '1.25rem', sm: '0rem' }}
          gap={{
            base: '.25rem',
            sm: '1.75rem',
            lg: '.75rem',
            xl: '1rem',
            '2xl': '1.75rem',
          }}
        >
          <Flex align={'start'} flexDir={'column'}>
            <Title>Bem vindo, Tutor</Title>
            <SubTitle>Por favor, insira seu CPF e senha.</SubTitle>
          </Flex>

          <Stack
            as="form"
            spacing={'0.75rem'}
            onSubmit={handleSubmit(handleSignIn)}
            mt={'0'}
          >
            <Input
              label={'CPF'}
              _required={false}
              type={'text'}
              mask={'999.999.999-99'}
              placeholder="000.000.000-00"
              error={errors.cpf}
              {...register('cpf')}
            />

            <Password
              label={'Senha'}
              _required={false}
              placeholder="Digite sua senha"
              error={errors.password}
              {...register('password')}
            />

            <Button
              type={'submit'}
              marginTop={6}
              bgColor={'blue.50'}
              _hover={{ bgColor: 'blue.200' }}
              color={'platinum.50'}
              size={'md'}
              isLoading={isSubmitting}
              loadingText={'Entrando'}
              lineHeight={'1.75rem'}
            >
              Entrar
            </Button>

            <ChakraLink
              as={Link}
              to="redefine-password"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={'blue.50'}
              lineHeight={'1.188rem'}
            >
              Esqueceu sua senha?
            </ChakraLink>
          </Stack>
          <Flex direction={'column'} >
            <Subscribe />
            <FaleConosco onOpen={onOpen} />
          </Flex>
          <SocialMedia />
        </Stack>
        <RNSethas />
      </Flex>
    </>
  )
}
