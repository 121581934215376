import { Icon } from '@chakra-ui/icons'
import { HStack, Link } from '@chakra-ui/react'
import {
  RiFacebookBoxLine,
  RiInstagramLine,
  RiTwitterLine,
  RiYoutubeLine,
} from 'react-icons/ri'

export function SocialMedia() {
  return (
    <HStack
      spacing={4}
      justifyContent="center"
      alignItems={'normal'}
      height={'2.75rem'}
      pt={'1.25rem'}
    >
      <Link href="https://www.instagram.com/rnsethas/" isExternal>
        <Icon as={RiInstagramLine} fontSize={'1.5rem'} />
      </Link>
      <Link href="https://www.youtube.com/@SethasRN" isExternal>
        <Icon as={RiYoutubeLine} fontSize={'1.5rem'} />
      </Link>
      <Link href="https://www.facebook.com/rnsethas" isExternal>
        <Icon as={RiFacebookBoxLine} fontSize={'1.5rem'} />
      </Link>
      <Link href="https://twitter.com/rnsethas" isExternal>
        <Icon as={RiTwitterLine} fontSize={'1.5rem'} />
      </Link>
    </HStack>
  )
}
