import * as yup from 'yup'

export const RedefinePasswordSchema = yup.object().shape({
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CPF deve conter apenas números')
    .min(11, 'CPF deve ter no mínimo 11 caracteres')
    .max(11, 'CPF deve ter no máximo 11 caracteres'),
  codigo: yup
    .string()
    .required('Código é obrigatório')
    .min(6, 'Código deve ter no mínimo 6 caracteres')
    .max(6, 'Código deve ter no máximo 6 caracteres')
    .uppercase(),
  nova_senha: yup
    .string()
    .required('Nova senha é obrigatória')
    .min(8, 'Senha deve ter no mínimo 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*()+.,;?{}^><:-])[A-Za-z\d!@#$%&*()+.,;?{}^><:-]{8,}$/,
      'Senha deve ter letras maiúsculas, minúsculas, números e caracteres especiais (!@#$%&*()+.,;?{}^><:-)',
    )
    .trim(),
  confirmar_nova_senha: yup
    .string()
    .oneOf([yup.ref('nova_senha'), null], 'Senhas não conferem'),
})
