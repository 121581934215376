import { CardContent } from './CardContent'
import { CardInfo } from './CardInfo'
import { CardJustification } from './CardJustification'
import { CardLabel } from './CardLabel'
import { CardLink } from './CardLink'
import { CardRoot } from './CardRoot'
import { CardTitle } from './CardTitle'
import { CardWrap } from './CardWrap'

export const Card = {
  Content: CardContent,
  Info: CardInfo,
  Label: CardLabel,
  Justification: CardJustification,
  Link: CardLink,
  Root: CardRoot,
  Title: CardTitle,
  Wrap: CardWrap,
}
